import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'

import Dashboard from './Dashboard'

const mapStateToProps = (state: State) => ({
  role: state.auth.role,
  organizationName: state.auth.organizationName,
  reconcilePartner: state.auth.reconcilePartner,
})

export default connect(mapStateToProps, null)(Dashboard)
