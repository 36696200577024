import React, { useRef, useState } from 'react'
import Moment from 'moment';
import Icon from 'antd/lib/icon'

import { Button } from 'common/components/Button'
import { Input } from 'common/components/Input'
import { InputIcon } from 'common/components/InputIcon'
import { DatePicker } from 'common/components/DatePicker'

import PaginatedTable from 'common/components/PaginatedTable'

import useFetchTransactions from 'common/hooks/useFetchTransactions'
import useDownloadCSV from 'common/hooks/useDownloadCSV'

import { Transaction } from 'common/store/schema/transactions'
import getCurrencyToLocale from 'common/utils/getCurrencyToLocale'
import { Role, RoleType } from 'common/store/auth/slice';
import { Tooltip } from 'antd';
import { Modal } from 'antd';
import { DateTime } from 'luxon';
import useCopyToClipboard from 'common/hooks/useCopyToClipboard';
import { client } from 'common/api/client';
import { openNotification } from 'common/components/openNotification';

interface TransactionsTableProps {
  fetchUrl: string,
  role?: Role,
  displayContainer?: boolean,
  organizationName?: string,
  allTransaction?: boolean
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  fetchUrl,
  role,
  displayContainer,
  allTransaction,
  organizationName
}) => {
  const dateFormat = 'YYYY-MM-DD'
  const defaultFromDate =  allTransaction ? Moment("01-01-2019").utc().format() : Moment().utc()
    .subtract(7, 'd')
    .startOf('day')
    .format()
  const downloadButtonInvisibility = allTransaction ? true : false
  const defaultToDate = Moment().utc().endOf('day').format()
  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)
  const [currentPage, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sortBy, setSortBy] = useState("created_at")
  const [order, setOrder] = useState("DESC")
  const [filterByBrand, setFilterByBrand] = useState("")
  const [filterByOrderId, setFilterByOrderId] = useState("")
  const [filterByStatus, setFilterByStatus] = useState("")
  const [filterByPartnerName, setFilterByPartnerName] = useState("")
  const [filterByClientProductId, setFilterByClientProductId] = useState("")
  const [filterBySupplierProductId, setFilterBySupplierProductId] = useState("")
  const [filterByTransactionType, setFilterByTransactionType] = useState("")
  const [disableDownloadButton, setDisaleDownloadButton] = useState(downloadButtonInvisibility)

  const [filterByExternalUserId, setFilterByExternalUserId] = useState("")
 
  const orderIdInputRef = useRef(null)
  
  const { data, total, isLoading, lastV1TransactionTime } = useFetchTransactions(
    fetchUrl,
    currentPage,
    perPage,
    fromDate,
    toDate, sortBy, order, 
    filterByBrand === "" ? null : filterByBrand,
    filterByOrderId === "" ? null : filterByOrderId,
    filterByStatus === "" ? null : filterByStatus,
    filterByPartnerName === "" ? null : filterByPartnerName,
    filterByClientProductId === "" ? null : filterByClientProductId,
    filterBySupplierProductId === "" ? null : filterBySupplierProductId,
    filterByTransactionType === "" ? null : filterByTransactionType,
    filterByExternalUserId === "" ? null : filterByExternalUserId
  )

  const onSortChange = (sortBy: string, order: string | null) => {

    setSortBy(sortBy)
    
    if(order)
      setOrder(order)
  }
  const { downloadCSVcallback } = useDownloadCSV(`${fetchUrl}.csv`, organizationName)

  const onPageChange = (currentPage: number) => {
    setPage(currentPage)
  }

  //Disable the date toDate which is less than start date or greater than today's date
  const disabledDate = (value: Moment.Moment | null) =>{
    const endDate = Moment(value).utc().startOf('day').format()
   // Can not select days before today and today
   return  endDate < fromDate || endDate > Moment.utc().endOf('day').format();
  }; 

  //Disable the date fromDate which is less than equal to today's date
  const disabledStartDate = (value: Moment.Moment | null) =>{
    const startDate = Moment(value).utc().startOf('day').format()   
    return  startDate > Moment.utc().endOf('day').format();
  }; 

  const onPageSizeChange = (value: number) => {
    setPerPage(value)
    setPage(1)
  }

  const updateToDate = (value: string | undefined) => {
    if (value) {
      // Moment(value).utc().format()
      setToDate(value)
      setPage(1)
    }
  }

  const updateFromDate = (value: string | undefined) => {
    if (value) {
      // Moment(value).utc().format()
      setFromDate(value)
      setPage(1)
    }
  }

  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {

    if(filterField === "brandName")
      onSearched("brandName", filterValue)

    if(filterField === "orderId")
      onSearched("orderId", filterValue)

    if(filterField === "status")
      onSearched("status", filterValue)

    if(filterField === "organizationName")
      onSearched(filterField, filterValue)

    if(filterField === "productId")
      onSearched(filterField, filterValue)

    if(filterField === "supplierFacingProductId")
      onSearched(filterField, filterValue)

    if(filterField === "transactionType")
      onSearched(filterField, filterValue)

    if(filterField === "externalUserId")
      onSearched(filterField, filterValue)
  }
   
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // });

    // console.log("handleSearch");
    // console.log(selectedKeys);

    // var filterField = Object.keys(selectedKeys)[0];

    // onSearched(filterField,selectedKeys[filterField][0])
    // setPage(1)
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();

    // this.setState({ searchText: '' });
    // onSearched("brandName", null)
    setPage(1)
  };

  const onSearched = (field:string, value: string | null) => {
    if(field === "brandName")
      setFilterByBrand(value ? value : "");
    else if(field === "orderId")
      setFilterByOrderId(value ? value : "")
    else if(field === "status")
      setFilterByStatus(value ? value : "")
    else if(field === "organizationName")
      setFilterByPartnerName(value ? value : "")
    else if(field === "productId")
      setFilterByClientProductId(value ? value : "")
    else if(field === "supplierFacingProductId")
      setFilterBySupplierProductId(value ? value : "")
    else if(field === "transactionType")
      setFilterByTransactionType(value ? value : "")
    else if(field === "externalUserId")
      setFilterByExternalUserId(value ? value : "")
    setPage(1)

    if(role === RoleType.SuperAdmin || role === RoleType.PhazeAdmin)
      setDisaleDownloadButton(field && value ? false : true)
  }

  const  searchData=(inputRef: any)=> {
    const orderId = inputRef?.current?.input?.value ? (inputRef.current.input.value) : ""
    onFilterApplied("orderId",orderId)
  }

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{setSelectedKeys: any, selectedKeys: any,confirm:any, clearFilters:any}) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys?selectedKeys[0]:""}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, padding: 0 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
      </div>
      
    ),

    filterIcon: (filtered:any) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) => {
      
      return true;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // setTimeout(() => searchInput.select());
      }
    },
  });
  
  const errorColumnName = (role === RoleType.PartnerAdmin || role === RoleType.PartnerUser) ? 'Error Message' :'error';
  const voucherColumnName = (role === RoleType.PartnerAdmin || role === RoleType.PartnerUser) ? 'Voucher' : 'Response Data' ;
  const voucherColumnNameKey = (role === RoleType.PartnerAdmin || role === RoleType.PartnerUser)  ? 'voucher' : 'responseData' ;

  //The info to provide
  const columnInfo : {[key: string] : string} = {
    Date: "Date the transaction occured",
    ['Partner Share(%)']: "The percentage of the discount partner will receive.",
    ['Vendor Name']: "The Phaze Aggregator which has fulfilled the transaction.",
    ['MarginOffSet Profit(CAD)'] : 'The Phaze Profit from discount which is not shared with Partner',
    ['Partner Currency']: 'The currency in which partner paid.',
    ['Cost']: 'The cost of the transaction which partner pays',
    ['Currency Conversion']: 'The partner currency to voucher currency conversion rate which is used to charge the partner (incl. Fx Spread).',
    ['Currency Conversions Initial'] : 'The partner currency to voucher currency conversion rate which is used to charge the partner ( without Fx Spread).',
    ['Fx Spread Percent']: 'The Fx Spread Percent charged onto the conversion.',
    ['Margin Offset']: 'The margin phaze cut from discount.',
    ['Phaze Original Rate']: 'From voucher currency to Phaze currency [CAD] conversion ( incl. FxSpread)',
    ['Phaze Applied Rate']: 'From voucher currency to Phaze currency [CAD] conversion ( excluding FxSpread)',
    ['Phaze Commission (CAD)']: 'The Phaze profit from commission',
    ['PhazeFxRevenue (CAD)']: 'The Phaze Profit from fx spread applied to the transaction.',
    ['Transaction fee Conversion']: 'The transaction fee conversion incl Fx Rate',
    ['Transaction fee Conversion Initial']: 'The transaction fee conversion excluding Fx Rate',
    ['Transaction Fee Type']: 'The Transaction Fee Type',
    ['Transaction fee in Phaze Currency']: 'The transaction fee cost in phaze currency (CAD)',
    ['Total Phaze Revenue']: 'The total revenue which includes phaze revenue from transaction fee, margin offset, fx Spread and commission'
  }

  /**
   * @description Dynamically assign a title to a column
   * @param title string which will be the title
   * @returns HTMl element to render as a title
   */
  const titleWithInfo = (title : string) =>{
    if(role === RoleType.SuperAdmin || role === RoleType.PhazeAdmin){
      return (
      <>
        <Tooltip placement='top' title={columnInfo[title]}>
          {title}
          <Icon
            style={{fontSize: '17px',cursor: 'pointer',userSelect: 'none',}}
            type='question-circle'
          /></Tooltip>
      </>)
    }else{
      return (<>{title}</>)
    }
  }

  const [, copyToClipboard] = useCopyToClipboard()

  const columns = [
    {
      title: () => titleWithInfo('Date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string, record: Transaction) => {
        return <div>{Moment(date).format('YYYY-MM-DD')}</div>
      },
      sorter: (a: any, b: any) => {
        return 0
      },
      width: 100
    },
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      key: 'orderId',
      width: 140,
      sorter: (a: any, b: any) => {
        return 0
      },
      ...getColumnSearchProps('orderId')
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 150,
      sorter: (a: any, b: any) => {
        return 0
      },
      ...getColumnSearchProps('brandName')
    },
    {
      title: 'Denomination',
      dataIndex: 'voucherValue',
      width: 150,
      key: 'voucherValue',
      align: 'center' as 'center',
      render: (voucherValue: number, record: Transaction) =>
        getCurrencyToLocale(voucherValue, record.voucherCurrency),
      sorter: (a: any, b: any) => {
        return 0
      }
    },
    {
      title: 'Discount %',
      dataIndex: 'voucherDiscountPercent',
      align: 'center' as 'center',
      width: 120,
      key: 'voucherDiscountPercent',
      render: (response: string) => {
        return (+response).toFixed(2)
      },
      sorter: (a: any, b: any) => {
        return 0
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => {
        return 0
      },
      filters: [
        {
          text: 'Processed',
          value: 'processed'
        },
        {
          text: 'Failed',
          value: 'failed'
        },
        {
          text: 'Pending',
          value: 'pending'
        }
      ],
      onFilter: (value: any, record: any) => true,
      filterMultiple: false,
      width: 110
    },
    {
      title: 'Show details',
      align: 'center' as 'center',
      width: 120,
      key: 'transactionDetails',
      render: (_: any, record: Transaction) => {
        const Component = () => {
          const [show, setShow] = useState(false)

          return (
            <>
              <Modal
                visible={show}
                onCancel={() => setShow(false)}
                onOk={() => setShow(false)}
              >
                <div>
                  <h2>Transaction details</h2>
                  <div style={{ fontWeight: 'bold' }}>Before</div>
                  <p>
                    Product Discount Percent:{' '}
                    {record.transactionDetails?.before.productDiscountPercent}
                  </p>

                  <p>
                    Product Price:{' '}
                    {record.transactionDetails?.before.productPrice}
                  </p>

                  <p>
                    Partner Commission:{' '}
                    {record.transactionDetails?.before.partnerCommission}
                  </p>

                  <p>
                    Phaze Commission:{' '}
                    {record.transactionDetails?.before.phazeCommission}
                  </p>

                  <p>
                    Product Price In Account Currency:{' '}
                    {
                      record.transactionDetails?.before
                        .productPriceInAccountCurrency
                    }
                  </p>

                  <p>
                    Cost for transaction:{' '}
                    {record.transactionDetails?.before.costForTransaction}
                  </p>

                  <p>
                    Transaction Fee in account currency:{' '}
                    {
                      record.transactionDetails?.before
                        .transactionFeeInAccountCurrency
                    }
                  </p>

                  {record?.transactionDetails?.after && (
                    <>
                      <p style={{ fontWeight: 'bold' }}>After</p>

                      <p>
                        Product Discount Percent:{' '}
                        {
                          record.transactionDetails?.after
                            ?.productDiscountPercent
                        }
                      </p>

                      <p>
                        Product Price:{' '}
                        {record.transactionDetails?.after?.productPrice}
                      </p>

                      <p>
                        Partner Commission:{' '}
                        {record.transactionDetails?.after?.partnerCommission}
                      </p>

                      <p>
                        Phaze Commission:{' '}
                        {record.transactionDetails?.after?.phazeCommission}
                      </p>

                      <p>
                        Product Price In Account Currency:{' '}
                        {
                          record.transactionDetails?.after
                            ?.productPriceInAccountCurrency
                        }
                      </p>

                      <p>
                        Cost for transaction:{' '}
                        {record.transactionDetails?.after?.costForTransaction}
                      </p>
                    </>
                  )}
                </div>
              </Modal>
              <Button
                disabled={!record.transactionDetails}
                onClick={() => setShow(true)}
              >
                Show Details
              </Button>
            </>
          )
        }

        return <Component />
      },
      sorter: (a: any, b: any) => {
        return 0
      }
    },
    {
      title: 'Actions',
      align: 'center' as 'center',
      width: 120,
      render: (_: any, record: Transaction) => {
        const Component = () => {
          const [loading, setLoading] = useState(false)
          const [isBlocked, setIsBlocked] = useState(record.isBlocked)

          const unblockCard = () => {
            if(!window.confirm('Are you sure you want to unblock this card?')) return
            
            setLoading(true)
            client
              .post('/unblock-card', { orderId: record.orderId })
              .then(r => {
                openNotification(r.data.message, 'success')
                setIsBlocked(false)
              })
              .catch(e => {
                openNotification(
                  e.response.data?.error || 'Something went wrong',
                  'error'
                )
              })
              .finally(() => {
                setLoading(false)
              })
          }

          return (
            <Button
              onClick={unblockCard}
              loading={loading}
              disabled={!isBlocked}
            >
              Unblock
            </Button>
          )
        }

        return <Component />
      },
      sorter: (a: any, b: any) => {
        return 0
      }
    }
  ]

  if(allTransaction && (role === 'super_admin' || role === RoleType.PhazeAdmin)){
    let allTxn = [
      {
        title: "Organisation Name",
        dataIndex: 'organizationName',
        key: 'organizationName',
        width: 250,
        align: 'right' as 'right',
        sorter: (a: any, b: any) => { return 0 },
        ...getColumnSearchProps('organizationName')    
      },
      {
        title: "Client ProductId",
        dataIndex: 'productId',
        key: 'productId',
        width: 250,
        align: 'right' as 'right',
         ...getColumnSearchProps('productId'),    
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: "Supplier ProductId	",
        dataIndex: 'supplierFacingProductId',
        key: 'supplierFacingProductId',
        width: 250,
        align: 'right' as 'right',
        ...getColumnSearchProps('supplierFacingProductId'),    
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: "External UserIdD",
        dataIndex: 'externalUserId',
        key: 'externalUserId',
        width: 200,
        align: 'right' as 'right',
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo("Partner Share(%)"),
        dataIndex: 'partnerRevenueShare',
        key: 'partnerRevenueShare',
        width: 200,
        align: 'center' as 'center',
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo("Vendor Name"),
        dataIndex: 'transactionType',
        key: 'transactionType',
        width: 250,
        align: 'center' as 'center',
        render: (response: string) => {
          return response
        },
        filters: [
          {
            text: 'xoxoDay legacy',
            value: 'xoxoDay',
          },
          {
            text: 'xoxoDay Plum',
            value: 'plum',
          },
          {
            text: 'BlackHawk',
            value: 'blackHawk',
          },
          {
            text: 'Tango',
            value: 'tango'
          },
          {
            text: 'Tillo',
            value: 'tillo'
          },
          {
            text: 'Mooments',
            value: 'momments'
          },
          {
            text: 'QwikCilver',
            value: 'qc'
          }
        ],
        onFilter: (value: any, record: any) => true,
        filterMultiple: false,
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo("Partner Currency"),
        dataIndex: 'currencyPaid',
        key: 'currencyPaid',
        width: 300,
        align: 'right' as 'right',
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      }      
    ]
    columns.push(...allTxn)
  }

  const col = [{
    title: () => titleWithInfo("Cost"),
    dataIndex: 'cost',
    key: 'cost',
    align: 'right' as 'right',
    render: (cost: number, record: Transaction) =>
      getCurrencyToLocale(cost, record.currencyPaid),
    sorter: (a: any, b: any) => { return 0},
    width:150
  }]

  columns.push(...col)

  if(role  === 'partner_admin'){
    let partnerAdminData = [
      {
        title: 'Commission Earned',
        dataIndex: 'commission',
        key: 'commission',
        align: 'right' as 'right',
        width: 150,
        render: (commission: number, record: Transaction) =>
          getCurrencyToLocale(commission, record.currencyPaid)
      },
      {
        title: 'Currency Conversion',
        dataIndex: 'currencyConversions',
        key: 'currencyConversions',
        width: 250,
        align: 'center' as 'center',
        render: (response: object) =>
          response && Object.keys(response).length
            ? JSON.stringify(response)
            : 'N/A'
      },
      {
        title: 'External User id',
        dataIndex: 'externalUserId',
        key: 'externalUserId',
        width: 300,
        align: 'right' as 'right',
        ...getColumnSearchProps('externalUserId'),
        render(text: any) {
          const onClick = () => {
            copyToClipboard(text)
            openNotification('Copied to clipboard', 'success')
          }

          return (
            <div style={{display: 'flex', placeItems: 'center', justifyContent: 'space-between', gap: 10}}>
              <div style={{ width: '100%', }}>
              {text}
              </div>
              <Icon
                style={{
                  cursor: 'pointer'
                }}
                onClick={onClick}
                type='copy'
              />
            </div>
          )
        }
      }
    ]
    columns.push(...partnerAdminData)
  }

  if(role === RoleType.SuperAdmin || role === RoleType.PhazeAdmin){
    let superAdminData = [
      {
        title: () => titleWithInfo('Currency Conversion'),
        dataIndex: 'currencyConversions',
        key: 'currencyConversions',
        width: 250,
        align: 'right' as 'right',
        render: (response: object) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0}
      },
      {
        title: () => titleWithInfo('Currency Conversions Initial'),
        dataIndex: 'currencyConversionsInitial',
        key: 'currencyConversionsInitial',
        width: 250,
        align: 'right' as 'right',
        render: (response: object) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      
      {
        title: 'Mobile Topup Fx Spread Percent',
        dataIndex: 'topUpFxSpreadPercent',
        key: 'topUpFxSpreadPercent',
        width: 100,
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Phaze Applied Rate'),
        dataIndex: 'phazeConversion',
        key: 'phazeConversion',
        width: 200,
        render: (response: object) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Phaze Original Rate'),
        dataIndex: 'phazeCurrencyConversionsInitial',
        key: 'phazeCurrencyConversionsInitial',
        width: 200,
        align: 'right' as 'right',
        render: (response: object) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0}
      },
      {
        title: () => titleWithInfo('Transaction fee Conversion'),
        dataIndex: 'transactionFeeCurrencyConversion',
        key: 'transactionFeeCurrencyConversion',
        width: 200,
        render: (response: string) => {
          return response
        },
        //sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Transaction fee Conversion Initial'),
        dataIndex: 'transactionFeeCurrencyConversionInitial',
        key: 'transactionFeeCurrencyConversionInitial',
        width: 200,
        render: (response: string) => {
          return response
        },
        //sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Fx Spread Percent'),
        dataIndex: 'fxSpreadPercent',
        key: 'fxSpreadPercent',
        width: 200,
        align: 'right' as 'right',
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('PhazeFxRevenue (CAD)'),
        dataIndex: 'phazeFxRevenueInCAD',
        key: 'phazeFxRevenueInCAD',
        width: 200,
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Margin Offset'),
        dataIndex: 'marginOffset',
        key: 'marginOffset',
        width: 180,
        align: 'right' as 'right',
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('MarginOffSet Profit(CAD)'),
        dataIndex: 'MarginOffSetValueInCAD',
        key: 'MarginOffSetValueInCAD',
        width: 300,
        align: 'right' as 'right',
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Phaze Commission (CAD)'),
        dataIndex: 'phazeCommissionInCAD',
        key: 'phazeCommissionInCAD',
        width: 200,
        render: (response: string) => {
          return response
        },
        sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Transaction Fee Type'),
        dataIndex: 'transactionFeeType',
        key: 'transactionFeeType',
        width: 200,
        render: (response: string) => {
          return response
        },
        //sorter: (a: any, b: any) => { return 0 }
      },
      {
        title: () => titleWithInfo('Transaction fee in Phaze Currency'),
        dataIndex: 'transactionFeeInPhazeCurrency',
        key: 'transactionFeeInPhazeCurrency',
        width: 200,
        render: (response: string) => {
          return response
        },
        //sorter: (a: any, b: any) => { return 0 } 
      },
      {
        title: () => titleWithInfo('Total Phaze Revenue'),
        dataIndex: 'totalPhazeRevenue',
        key: 'totalPhazeRevenue',
        width: 200,
        render: (response: string) => {
          return response
        },
        //sorter: (a: any, b: any) => { return 0 }
      },
    ]
    columns.push(...superAdminData)
  }

  if(role !== RoleType.PhazeAdmin){
    const responseData = [
      {
        title: errorColumnName,
        dataIndex: 'error',
        key: 'error',
        align: 'right' as 'right',
        width:350,
        sorter: (a: any, b: any) => { return 0}
      },
      {
        title: voucherColumnName,
        dataIndex: voucherColumnNameKey,
        key: voucherColumnNameKey,
        width: 2500,
        align: 'right' as 'right',
        render: (response: object) => {
          return JSON.stringify(response)
        },
      }
    ]
    columns.push(...responseData)
  }
  

  return (
    <>
      <div
        style={{
          display: 'flex',
          placeItems: 'center',
          gap: 4,
          justifyContent: 'space-between'
        }}
      >
        <div>
          {lastV1TransactionTime && (
            <p>
              To see transactions made before{' '}
              <strong>
                {DateTime.fromJSDate(new Date(lastV1TransactionTime)).toFormat(
                  'd MMM, yyyy'
                )}
              </strong>
              , please download the CSV file by clicking the{' '}
              <strong>Download CSV</strong> button.
            </p>
          )}
        </div>

        <div style={{ display: 'flex', placeItems: 'center', gap: 5 }}>
          {allTransaction ? (
            <>
              <Input
                placeholder='search by orderId'
                style={{ width: 200 }}
                ref={orderIdInputRef}
                prefix={<InputIcon type='search' />}
              />
              <Button
                type='primary'
                shape='round'
                htmlType='submit'
                onClick={() => searchData(orderIdInputRef)}
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <Button
                type='default'
                shape='round'
                onClick={() => downloadCSVcallback(fromDate, toDate)}
              >
                <Icon type='download' />
                Download CSV
              </Button>
            </>
          )}

          <DatePicker
            allowClear={false}
            defaultValue={Moment(fromDate).utc()}
            format={dateFormat}
            disabledDate={disabledStartDate}
            onChange={date =>
              updateFromDate(
                date
                  ?.utc()
                  .startOf('day')
                  .format()
              )
            }
            data-testid='test-from-date'
          />
          <DatePicker
            allowClear={false}
            defaultValue={Moment(toDate).utc()}
            format={dateFormat}
            disabledDate={disabledDate}
            onChange={date =>
              updateToDate(
                date
                  ?.utc()
                  .endOf('day')
                  .format()
              )
            }
            data-testid='test-to-date'
          />
        </div>
      </div>

      <PaginatedTable
        total={total}
        loading={isLoading}
        columns={columns}
        data={data}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        currentPage={currentPage}
        pageSize={perPage}
        onSortChange={onSortChange}
        onFilterApplied={onFilterApplied}
        rowKey='orderId'
        displayContainer={displayContainer}
      />

    
    </>
  )
}

export default TransactionsTable
