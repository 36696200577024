import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'
import PartnerErrorLogs from './PartnerErrorLogs'

const mapDispatchToProps = {}

const mapStateToProps = (state: State) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerErrorLogs)
