import styled from 'styled-components'
import { Button } from 'common/components/Button'
import { Icon } from 'antd'

export const ContentContainer = styled.div`
  padding: 26px 26px 16px;
  text-align: center;

  h2 {
    margin: 30px 0 24px;
    font-size: 24px;
  }
  form {
    text-align: left;

    > div {
      margin-bottom: 14px;
    }
  }
`
export const StyledName = styled.span`
  color: ${({ theme }) => theme.colours.primary};
`

export const StyledSubmitButton = styled(Button)`
  display: block;
  margin: 20px auto 0;
`
export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colours.modalWarning};
  font-size: 36px;
`
