import { useEffect, useState } from 'react'
import { client } from 'common/api/client'

const useFetchTopUp = (
  url: string,
  currentPage: number,
  perPage: number,
  fromDate: string,
  toDate: string,
  sortBy: string,
  order: string,
) => {
  const [data, setData] = useState<[]>([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const response = await client.get(url, {
          params: {
            currentPage,
            perPage,
            fromDate,
            toDate, sortBy, order
          }
        })
        setData(response.data.result)
        setTotal(response.data.totalCount)
      } catch (error) {
        setError(true)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [url, currentPage, perPage, fromDate, toDate, sortBy, order])

  return { data, isLoading, error, total }
}

export default useFetchTopUp
