import React from 'react'
import Pagination from './Pagination'
import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { TableContainer } from './styledComponents'
import { ContentContainer } from '../ContentContainer'


interface PaginatedTableProps {
  total: number
  loading: boolean
  data: object[]
  columns: ColumnProps<any>[]
  onPageChange: (page: number) => void
  onPageSizeChange: (size: number) => void
  currentPage: number
  pageSize: number
  onSortChange:(sortBy: string, order: string | null) => void
  onFilterApplied:(filterField: string | null, filterValue: string | null) => void
  rowKey: string
  displayContainer?: boolean
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  onPageSizeChange,
  onPageChange,
  onSortChange,
  onFilterApplied,
  total,
  data,
  columns,
  loading,
  currentPage,
  pageSize,
  rowKey,
  displayContainer
}) => {

  const handleChange = (pagination: any, filters: any, sorter: any) => {

    var order =  null;
    if(sorter.order === "ascend")
      order="ASC"
    else if (sorter.order === "descend")
      order= "DESC"
    else
      order = null

    onSortChange(order ? sorter.field : null, order);

    Object.keys(filters).forEach(key => {
      onFilterApplied( key, filters[key][0] )
    });
  }

  return (
    <TableContainer>
      {displayContainer && (
        <ContentContainer>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={false}
            rowKey={rowKey}
            scroll={{ x: true }}
            onChange={handleChange}
            size="small"
          />
        </ContentContainer>
      )}

      {!displayContainer && (
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          rowKey={rowKey}
          scroll={{ x: true }}
          onChange={handleChange}
          size="small"
        />
      )}
      <Pagination
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={{ current: currentPage, total: total, pageSize: pageSize }}
      />
    </TableContainer>
  )
}
export default PaginatedTable
