import React from 'react'
import { Field } from 'formik'
import FieldInput from 'common/components/FieldInput'

import { Icon } from 'antd'
import { theme } from 'common/styles/theme'
import FieldSelect from 'common/components/FieldSelect'
import { Row, Col } from 'antd'
import { FieldProps } from 'formik'

interface ConfigureFormProps {
  currencyList: string[]
  withoutInitialFunding?: boolean
}
const ConfigureForm: React.FC<ConfigureFormProps> = ({
  currencyList,

  withoutInitialFunding: renderInitialFunding
}) => {

  const gettransactionFeeType = [
    {key : 'Flat Fees (USD)' , value: 'flat_fee_in_usd'},
    {key : 'Percetantage Fees (In Voucher Currency)' , value: 'percentage_fee' }
  ]

  const expiryMonths = ['1','2','3','4','5','6','7','8','9','10','11','12']
  const orderExpiryMonths = expiryMonths.map(m=>{
    return {
      key: "Order will expire in " + m + ' months',
      value: m
    }
  })
  
  return (
    <>
      {!renderInitialFunding && (
        <Field
          label='Initial Funding'
          name='prefundedAmount'
          placeholder={1000000.0}
          component={FieldInput}
          icon={<Icon type='dollar' style={{ color: theme.colours.primary }} />}
          required
        />
      )}

      {!renderInitialFunding && (
        <Field
          label='Base Currency'
          name='prefundCurrency'
          placeholder='Please Select'
          component={FieldSelect}
          as='select'
          options={currencyList.map(item => ({ key: item, value: item }))}
          required
        />
      )}

      <Field
        label='Top Up Warning at'
        name='prefundedAmountWarningTopupAmount'
        placeholder={5000}
        component={FieldInput}
        icon={<Icon type='dollar' style={{ color: theme.colours.warning }} />}
        required
      />
      <Field
        label='Low Warning at ( *Orders will not get processed)'
        name='prefundedAmountWarningLow'
        placeholder={0}
        component={FieldInput}
        icon={<Icon type='dollar' style={{ color: theme.colours.warning }} />}
        required
      />
      <Field
        label='Partners Commission'
        name='partnersCommission'
        placeholder={0}
        component={FieldInput}
        suffix='%'
        required
      />
      <Field
        label='Maximum Discount'
        name='maxDiscount'
        placeholder={0}
        component={FieldInput}
        suffix='%'
        required
      />
      <Field
        label='Fx Spread percent : [ (Fx Spread Percent/100) * voucherPrice ] * Applied When voucherCurrency is not same as Partner Account Currency'
        name='fxSpreadPercent'
        placeholder={0}
        component={FieldInput}
        suffix='%'
        required
      />
      <Field
        label='Mobile Top Up Fx Spread Percent'
        name='topUpFxSpreadPercent'
        placeholder={0}
        component={FieldInput}
        suffix='%'
        required
      />
      <Field
        label='Margin Offset :  [ voucherPrice * (Margin Offset/100) ]'
        name='marginOffset'
        placeholder={0}
        component={FieldInput}
        suffix='%'
        required
      />
      <Field
        label='Breakage split Percent per partner'
        name='breakageSplitPercent'
        placeholder={0}
        component={FieldInput}
        suffix='%'
        required
      />
      <Field name='transactionExpiryInMonth' as='select'>
            {(fieldProps: FieldProps) => {
              return (
                <FieldSelect
                  label='Order Expiry ( It will be in month )'
                  placeholder='Please select'
                  options={orderExpiryMonths}
                  onChange={() => {
                  }}
                  {...fieldProps}
                />
              )
            }}
      </Field>

      <Row type='flex' justify='space-between'>
        <Col span={11}>
          <Field name='transactionFeeType' as='select'>
            {(fieldProps: FieldProps) => {
              return (
                <FieldSelect
                  label='Transaction Fees Type'
                  placeholder='Please select'
                  options={gettransactionFeeType}
                  onChange={() => {
                  }}
                  {...fieldProps}
                />
              )
            }}
          </Field>
        </Col>
        <Col span={11}>
          <Field
            label='Transaction Fees (% or $)'
            name='transactionFee'
            component={FieldInput}
            placeholder='0'
          />
        </Col>
      </Row>

      <Field
        label='Top Up Warning Email'
        name='topupWarningEmails'
        component={FieldInput}
        placeholder='name@domain.com'
        
      />
      <Field
        label='Partner Support Email Address ( This will be used in future for Rewards Page )'
        name='supportEmail'
        component={FieldInput}
        placeholder='name@domain.com'
        
      />
    </>
  )
}
export default ConfigureForm
