import ErrorLogs from './ErrorLogs'

import { fetchErrorLogs } from 'common/store/errorLogs/actions'
import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'

const mapDispatchToProps = {
    fetchErrorLogs,
}

const mapStateToProps = (state: State) => {
  return {
    errorLogsArray: state.errorLogs.errorLogsArray,
    totalErrors: state.errorLogs.totalErrorLogs,
    account: state.errorLogs.account
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLogs)
