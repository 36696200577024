import styled from 'styled-components'
import { Input } from 'common/components/Input'
import { Button } from 'antd'

export const StyledInput = styled(Input)`
  margin-bottom: 20px;

  input[disabled] {
    background: transparent;
  }
`

export const Label = styled.label`
  display: block;
  margin: 0px 0px 4px 12px;
`
export const Actions = styled.div`
  a {
    color: ${({ theme }) => theme.colours.primary};
  }

  > span {
    margin: 0 5px;
  }
`

export const ActionButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colours.primary};
`