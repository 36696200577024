import React from 'react'
import { Col } from 'antd'
import { FormRow, FormTitle } from './styledComponents'
interface FormContainerProps {
  title: string
}
const FormContainer: React.FC<FormContainerProps> = ({ title, children }) => {
  return (
    <FormRow>
      <Col span={9}>
        <FormTitle>{title}</FormTitle>
      </Col>
      <Col span={15}>{children}</Col>
    </FormRow>
  )
}

export default FormContainer
