import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Formik } from 'formik'
import { Icon, Row, Col, Form } from 'antd'

import { castToNumber, castToPercentage } from 'common/utils/common'
import { Button } from 'common/components/Button'
import { URL_ACCOUNTS } from 'common/api/paths'
import ViewContainer from 'common/components/ViewContainer'
import TransactionsTable from 'core/TransactionsTable'
import { History } from 'history'

import { Modal } from 'antd'
import {
  FinancialDetailsWithLayout as FinancialDetails,
  Tabs,
  TabPane,
  TabContent
} from './styledComponents'
import { tabs, fallbackFormValues } from './tabsConfig'
import { Account } from 'common/store/schema/accounts'
import AddFunds from './AddFunds'
import { editPartnerValidations } from '../validationsSchema'
import { RoutePaths } from 'core/Router/routePaths'
import TopUpTable from 'core/TopUpTable'
import { Role, RoleType } from 'common/store/auth/slice'

const { confirm } = Modal
interface ParamsProps {
  accountId: string
}

type EditPartnerProps = RouteComponentProps<ParamsProps> & {
  account: Account | null
  role: Role
  fetchAccountById: (id: number) => Promise<void>
  updateAccountById: (
    id: number,
    payload: { [key: string]: any }
  ) => Promise<void>
  archivePartner: (accountId: number,history: History) => void
  resetActiveAccount: () => void,
  history: History
}

const EditPartner: React.FC<EditPartnerProps> = props => {
  const {
    account,
    fetchAccountById,
    updateAccountById,
    resetActiveAccount,
    archivePartner,
    history,
    match: { params },
    role
  } = props

  const [openModal, setOpenModal] = useState(false)
  const [openTopUpModal, setOpenTopUpModal] = useState(false)

  const url = `${URL_ACCOUNTS}/${params.accountId}/transactions`
  const topupurl = `${URL_ACCOUNTS}/${params.accountId}/top-up-history`

  useEffect(() => {
    fetchAccountById(Number(params.accountId))
    return resetActiveAccount
  }, [fetchAccountById, params.accountId, resetActiveAccount, url])

  const editableFormValues = account
    ? {
        organizationName: account.organizationName,
        addressOne: account.addressOne,
        addressTwo: account.addressTwo,
        city: account.city,
        province: account.province,
        postalCode: account.postalCode,
        country: account.country,
        website: account.website,
        cryptoCompany: account.cryptoCompany,
        partnersCommission: String(account.partnersCommission),
        maxDiscount: String(account.maxDiscount),
        fxSpreadPercent: String(account.fxSpreadPercent),
        topUpFxSpreadPercent: String(account.topUpFxSpreadPercent),
        marginOffset: String(account.marginOffset),
        transactionFeeType: account.transactionFeeType ? String(account.transactionFeeType) : '',
        transactionFee: String(account.transactionFee),
        prefundedAmountWarningLow: String(account.prefundedAmountWarningLow),
        prefundedAmountWarningTopupAmount: String(
          account.prefundedAmountWarningTopupAmount
        ),
        topupWarningEmails: account.topupWarningEmails ? account.topupWarningEmails : '',
        supportEmail: account.supportEmail ? account.supportEmail : '',
        enableRewardsPage: account.enableRewardsPage,
        reconcilePartner: account.reconcilePartner,
        breakageSplitPercent: String(account.breakageSplitPercent),
        transactionExpiryInMonth: String(account.transactionExpiryInMonth) 
      }
    : null

  const initialFormValues = editableFormValues || fallbackFormValues

  const handleFormSubmit = async (values: { [key: string]: string }) => {
    const formattedValues = {
      ...values,
      partnersCommission: castToPercentage(values.partnersCommission),
      maxDiscount: castToNumber(values.maxDiscount),
      fxSpreadPercent: castToPercentage(values.fxSpreadPercent),
      topUpFxSpreadPercent: castToPercentage(values.topUpFxSpreadPercent),
      marginOffset: castToNumber(values.marginOffset),
      transactionFeeType: values.transactionFeeType,
      transactionFee: castToNumber(values.transactionFee),
      prefundedAmountWarningLow: castToNumber(values.prefundedAmountWarningLow),
      prefundedAmountWarningTopupAmount: castToNumber(
        values.prefundedAmountWarningTopupAmount
      ),
      breakageSplitPercent: castToPercentage(values.breakageSplitPercent),
      transactionExpiryInMonth: castToNumber(values.transactionExpiryInMonth)
    }
    console.log(formattedValues)
    await updateAccountById(Number(params.accountId), formattedValues)
  }
  
  
  const showConfirmation = (accounId: number, organisationName: string,history: History) => {
    confirm({
      title: 'Are you sure you want to Archive this Partner?',
      content: `Organisation Name: ${organisationName}`,
      okText: 'Yes',
      onOk() {
        archivePartner(accounId,history)
      }
    })
  }

  return (
    <ViewContainer
      title={account?.organizationName || ''}
      titleInBreadcrumb='Edit Partner'
      subPath='partners'
    >
      <Row type='flex' justify='space-between'>
        <Col span={16}>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={editPartnerValidations}
            onSubmit={handleFormSubmit}
          >
            {({ dirty, handleReset, handleSubmit, isSubmitting, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Tabs type='card'>
                  {tabs.map(tab => {
                    return (
                      <TabPane tab={tab.labelContent} key={tab.key}>
                        <TabContent>{tab.bodyContent}</TabContent>
                      </TabPane>
                    )
                  })}
                </Tabs>
                <Row type='flex' justify='space-between'>
                  <Button
                    type='ghost'
                    shape='round'
                    onClick={handleReset}
                    disabled={isSubmitting || !dirty}
                  >
                    Reset
                  </Button>

                  <Row type='flex' justify='end'>
                    <Button
                      style={{ marginRight: 20 }}
                      type='ghost'
                      shape='round'
                      onClick={() => history.replace(RoutePaths.PARTNERS)}
                    >
                      Cancel
                    </Button>
                    <Button
                      shape='round'
                      type='primary'
                      htmlType='submit'
                      disabled={
                        isSubmitting || !dirty || Object.keys(errors).length > 0
                      }
                    >
                      Save
                    </Button>
                  </Row>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col span={6}>
          <FinancialDetails
            balance={account?.prefundedAmount}
            currency={account?.prefundCurrency}
            commissions={account?.commissions}
            lastTopUp={
              account?.fundsAddedAt ? new Date(account.fundsAddedAt) : undefined
            }
            holdedPrefundedAmount={account?.holdedPrefundedAmount}
          />
          {account && role === RoleType.SuperAdmin && (
            <AddFunds account={account} fetchAccountById={fetchAccountById} />
          )}

          <Button
            type='ghost'
            shape='round'
            onClick={() => setOpenModal(true)}
            style={{ marginTop: 10 }}
          >
            <Icon type='audit' />
            See transaction history
          </Button>

          <Button
            type='ghost'
            shape='round'
            onClick={() => setOpenTopUpModal(true)}
            style={{ marginTop: 10 }}
          >
            <Icon type='dollar' />
            See Top Up History
          </Button>
          {account && (role === RoleType.SuperAdmin) && (
            <Button
              type='ghost'
              shape='round'
              onClick={() =>
                showConfirmation(account.id, account.organizationName, history)
              }
              style={{ marginTop: 10 }}
            >
              <Icon type='delete' style={{ color: '#F32013' }} />
              Archive Partner
            </Button>
          )}
          <Modal
            visible={openModal}
            footer={null}
            onCancel={() => setOpenModal(false)}
            width={'95vw'}
          >
            <TransactionsTable
              fetchUrl={url}
              organizationName={account?.organizationName}
            />
          </Modal>
          <Modal
            visible={openTopUpModal}
            footer={null}
            onCancel={() => setOpenTopUpModal(false)}
            width={'95vw'}
          >
            <TopUpTable
              fetchUrl={topupurl}
              accountCurrency={account?.prefundCurrency}
              accountId={account?.id}
              organizationName={account?.organizationName}
            />
          </Modal>
        </Col>
      </Row>
    </ViewContainer>
  )
}

export default EditPartner
