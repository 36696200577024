import { login } from 'common/store/auth/actions'
import { connect } from 'react-redux'
import SignIn from './SignIn'
import { State } from 'common/store/rootReducer'

const mapDispatchToProps = {
  login
}

const mapStateToProps = (state: State) => ({
  isSubmitting: state.auth.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
