import { Dispatch } from '@reduxjs/toolkit'
import { openNotification } from 'common/components/openNotification'
import {
  fetchBrandsDeltaRequest,
  fetchBrandsDeltaFailure,
  fetchBrandsDeltaSuccess,
} from './slice'
import { client } from 'common/api/client'
import { URL_CATALOG_AUDIT} from 'common/api/paths'


export const fetchBrands = (currentPage: number, perPage: number,
  sortBy: string, order: string,fromDate: string, toDate: string,
  filterByProductId: string, filterByProductName: string, filterByStatus: string) => async (dispatch: Dispatch) => {
  try {
  dispatch(fetchBrandsDeltaRequest())
  const res = await client.get(URL_CATALOG_AUDIT, {
                                params: {
                                  currentPage, perPage,sortBy, order,fromDate,
                                  toDate,filterByProductId, filterByProductName, filterByStatus       
                                }
                      })
  dispatch(fetchBrandsDeltaSuccess(res.data))
  } catch (err) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchBrandsDeltaFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}
