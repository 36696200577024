import React, { useEffect } from 'react'
import { Container, MainSection } from './styledComponents'
import { Layout } from 'antd'

import { RoutePaths } from 'core/Router/routePaths'
import { Switch, Route } from 'react-router-dom'

import AddNewPartner from 'core/Partners/AddNewPartner'
import AddTeamMember from 'core/Team/AddTeamMember'
import Dashboard from 'core/Dashboard'
import EditTeamMember from 'core/Team/EditTeamMember'
import Header from 'core/Header'
import Navigation from 'core/Navigation'
import Partners from 'core/Partners'
import Team from 'core/Team'
import EditPartner from 'core/Partners/EditPartner'
import History from 'core/History'
import Aggregators from 'core/Aggregators'
import Transactions from 'core/Transactions'
import { store } from 'common/store'
import Settings from 'core/Settings'
import { fetchApplicationData } from 'common/store/app/actions'
import BrandListType from 'core/BrandsFromType'
import SATransactions from 'core/Transactions/SATransaction'
import CatalogAudit from 'core/CatalogAudit'
import ErrorLogs from 'core/ErrorLogs'
import Broadcast from 'core/Broadcast'
import PartnerErrorLogs from '../PartnerErrorLogs'
import AddPersistentList from 'core/AddPersistentList'
const { Content } = Layout

const PageContainer: React.FC = () => {
  useEffect(() => {
    store.dispatch(fetchApplicationData())
  }, [])
  return (
    <Container>
      <Layout>
        <Navigation />
        <MainSection>
          <Header />
          <Content>
            <Switch>
              <Route path={RoutePaths.DASHBOARD} component={Dashboard} />
              <Route
                path={RoutePaths.ADD_NEW_PARTNER}
                component={AddNewPartner}
              />
              <Route
                path={`${RoutePaths.EDIT_PARTNER}/:accountId`}
                component={EditPartner}
              />
              <Route path={RoutePaths.PARTNERS} component={Partners} />
              <Route
                path={RoutePaths.ADD_NEW_TEAM_MEMBER}
                component={AddTeamMember}
              />
              <Route
                path={`${RoutePaths.EDIT_TEAM_MEMBER}/:userId`}
                component={EditTeamMember}
              />
              <Route path={RoutePaths.TEAM} component={Team} />
              <Route path={RoutePaths.HISTORY} component={History} />
              <Route path={RoutePaths.AGGREGATORS} component={Aggregators} />
              <Route path={RoutePaths.ERRORLOGS} component={ErrorLogs} />
              <Route path={RoutePaths.TRANSACTIONS} component={Transactions} />
              <Route path={RoutePaths.SETTINGS} component={Settings} />
              <Route path={RoutePaths.ADD_PERSISTENT_LIST}
                render={(props) => <AddPersistentList brandListType='mapped' />}
              />
              <Route path={RoutePaths.ADD_EXCLUSION_LIST}
                render={(props) => <AddPersistentList brandListType='exclusion' />}
              />
              <Route
                path={RoutePaths.SUPERADMINTRANSACTION}
                component={SATransactions}
              />
              <Route
                path={RoutePaths.EXCLUSION}
                render={(props) => <BrandListType brandListType='exclusion' />}
              />
              <Route
                path={RoutePaths.MAPPED_PRODUCTS}
                render={(props) => <BrandListType brandListType='mapped' />}
              />
              <Route path={RoutePaths.CATALOG_AUDIT} component={CatalogAudit} />
              <Route path={RoutePaths.BROADCAST} component={Broadcast} />
              <Route
                path={RoutePaths.MY_ERROR_LOGS}
                component={PartnerErrorLogs}
              />
              {/* <Route path={RoutePaths.MAPPED_PRODUCTS} component={MappedProducts} /> */}
            </Switch>
          </Content>
        </MainSection>
      </Layout>
    </Container>
  )
}

export default PageContainer
