import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../schema/team'
import { State } from '../rootReducer'

type TeamState = {
  users: User[]
  totalUsers: number
  loading: boolean
  error: null | string
}

type UsersPayload = {
  result: User[],
  totalCount: number
}

let initialState: TeamState = {
  users: [],
  totalUsers: 0,
  loading: false,
  error: null
}

function startLoading(state: TeamState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: TeamState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    fetchUsersRequest: startLoading,
    fetchUsersFailure: loadingFailed,
    fetchUsersSuccess(state, action: PayloadAction<UsersPayload>) {
      state.loading = false
      state.error = null
      state.users = action.payload.result
      state.totalUsers = action.payload.totalCount
    },
    createTeamMemberRequest: startLoading,
    createTeamMemberFailure: loadingFailed,
    createTeamMemberSuccess(state) {
      state.loading = false
      state.error = null
    },
    editTeamMemberRequest: startLoading,
    editTeamMemberFailure: loadingFailed,
    editTeamMemberSuccess(state) {
      state.loading = false
      state.error = null
    },
    deleteTeamMemberRequest: startLoading,
    deleteTeamMemberFailure: loadingFailed,
    deleteTeamMemberSuccess(state) {
      state.loading = false
      state.error = null
    }
  }
})

export const teamSelectors = {
  getUserById: (state: State, userId: number) => {
    return state.team.users.find(user => user.id === userId)
  }
}

export const {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  createTeamMemberRequest,
  createTeamMemberSuccess,
  createTeamMemberFailure,
  editTeamMemberRequest,
  editTeamMemberSuccess,
  editTeamMemberFailure,
  deleteTeamMemberRequest,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure
} = teamSlice.actions

export const teamReducer = teamSlice.reducer
