import React from 'react'
import {
  Wrapper,
  PageTitle,
  ViewHeader,
  SubPath,
  ActivePath
} from './styledComponents'
import { Breadcrumb, Icon } from 'antd'
import { NavLink } from 'react-router-dom'

interface ViewContainerProps {
  title: string
  titleInBreadcrumb?: string
  subPath?: string
}
const ViewContainer: React.FC<ViewContainerProps> = ({
  title,
  titleInBreadcrumb,
  subPath,
  children
}) => {
  return (
    <Wrapper>
      <ViewHeader>
        <PageTitle>{title}</PageTitle>
        {subPath && (
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to='/'>
                <Icon type='home' />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/${subPath}`}>
                <SubPath>{subPath}</SubPath>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <ActivePath>{titleInBreadcrumb || title}</ActivePath>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
      </ViewHeader>
      {children}
    </Wrapper>
  )
}

export default ViewContainer
