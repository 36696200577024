import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'

import Navigation from './Navigation'

const mapStateToProps = (state: State) => ({
  role: state.auth.role
})

export default connect(mapStateToProps, null)(Navigation)
