import React, { useEffect, useState, useMemo } from 'react'

import { Icon, Row, Col, Form } from 'antd'
import { Button } from 'common/components/Button'
import { URL_ACCOUNTS } from 'common/api/paths'
import { client } from 'common/api/client'

import FormContainer from 'common/components/FormContainer'
import FieldInput from 'common/components/FieldInput'
import FieldSelect from 'common/components/FieldSelect'

import { Formik, Field } from 'formik'
import validationsSchema from './validationsSchema'

import { RoutePaths } from 'core/Router/routePaths'
import { RouteComponentProps } from 'react-router-dom'

interface TeamMemberFormProps {
  onSubmit: (values: object) => void
  initialValues?: object
}

type AccountResponse = {
  organizationName: string
  id: number
}

type Option = {
  value: number | null
  key: string
}

const defaultOption: Option = { value: null, key: 'Select account' }

const TeamMemberForm: React.FC<TeamMemberFormProps & RouteComponentProps> = ({
  onSubmit,
  initialValues,
  history
}) => {
  const [accounts, setAccounts] = useState<AccountResponse[]>([])

  useEffect(() => {
    client
      .get(`${URL_ACCOUNTS}?perPage=all&fields[]=organizationName`)
      .then(res => {
        setAccounts(
          res.data.result.sort((a: AccountResponse, b: AccountResponse) => {
            if (a.organizationName < b.organizationName) {
              return -1
            } else if (a.organizationName > b.organizationName) {
              return 1
            }

            return 0
          })
        )
      })
  }, [])

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: 'partner_admin'
  }

  const accountOptions: Option[] = useMemo(() => {
    return [defaultOption].concat(
      accounts.map(account => ({
        value: account.id,
        key: account.organizationName
      }))
    )
  }, [accounts])

  return (
    <Formik
      initialValues={initialValues || defaultValues}
      validationSchema={validationsSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, dirty, errors }) => (
        <>
          <FormContainer title={'User Information'}>
            <Form id='setUser' onSubmit={handleSubmit}>
              <Row type='flex' justify='space-between'>
                <Col span={11}>
                  <Field
                    label='First Name'
                    name='firstName'
                    placeholder='Type-in name'
                    component={FieldInput}
                  />
                </Col>
                <Col span={11}>
                  <Field
                    label='Last Name'
                    name='lastName'
                    placeholder='Type-in last name'
                    component={FieldInput}
                  />
                </Col>
              </Row>
              <Field
                label='E-mail'
                name='email'
                placeholder='name@domain.com'
                component={FieldInput}
              />
              <Field
                label='Access Level'
                name='role'
                options={[
                  { key: 'Partner Admin', value: 'partner_admin' },
                  { key: 'Partner User', value: 'partner_user' },
                  { key: 'Super Admin', value: 'super_admin' },
                  { key: 'Phaze Admin', value: 'phaze_admin' },
                ]}
                placeholder='Please select'
                component={FieldSelect}
              />
              <Field
                label='Account'
                name='accountId'
                options={accountOptions}
                placeholder='Please select'
                component={FieldSelect}
              />
            </Form>
          </FormContainer>
          <Row type='flex' justify='end'>
            <Button
              type='ghost'
              shape='round'
              style={{ marginRight: 20 }}
              onClick={() => history.replace(RoutePaths.TEAM)}
            >
              Cancel
            </Button>
            <Button
              htmlType='submit'
              form='setUser'
              type='primary'
              shape='round'
              disabled={!dirty || Object.keys(errors).length > 0}
            >
              Save
              <Icon type='right' />
            </Button>
          </Row>
        </>
      )}
    </Formik>
  )
}

export default TeamMemberForm
