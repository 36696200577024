import SaaSSubscription from './SaaSForm'
import { connect } from 'react-redux'

import { State } from 'common/store/rootReducer'
import { accountSelectors } from 'common/store/accounts/slice'
import { SaaSSubscriptionKey, SaaSSubscriptionMode, setAccountSaaSDisable, updateSaaSSubscriptionForAccount } from 'common/store/accounts/actions'
import { SaaSSubscriptionModel } from 'common/store/schema/accounts'

const mapDispatchToProps = {
  setAccountSaaSDisable: (id: number,key : SaaSSubscriptionKey,mode: SaaSSubscriptionMode) => setAccountSaaSDisable(id,key,mode),
  updateSaaSSubscriptionForAccount: (id: number,subscriptionGroup : string,saaSSubscription : Array<SaaSSubscriptionModel>) => updateSaaSSubscriptionForAccount(id,subscriptionGroup,saaSSubscription),
}

const mapStateToProps = (state: State) => {
  const { currentAccountId } = state.accounts
  if (!currentAccountId) {
    return {
      id: null,
      prefundCurrency: '',
      disabledTopUpSaaSSubscription: true,
      disabledBrandSaaSSubscription: true,
      organizationName: '',
      topUpsubscriptionData : [],
      brandsubscriptionData: []
    }
  }

  const currentAccount = accountSelectors.getCurrentAccount(state)

  return {
    id: currentAccount.id,
    disabledTopUpSaaSSubscription: currentAccount.disabledTopUpSaaSSubscription,
    disabledBrandSaaSSubscription: currentAccount.disabledBrandSaaSSubscription,
    organizationName: currentAccount.organizationName,
    prefundCurrency: currentAccount.prefundCurrency,
    topUpsubscriptionData : currentAccount.SaaSSubscriptionData.filter(d=> d.saaSSubscriptionGroup === SaaSSubscriptionKey.TopUp),
    brandsubscriptionData: currentAccount.SaaSSubscriptionData.filter(d=> d.saaSSubscriptionGroup === SaaSSubscriptionKey.Voucher)
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SaaSSubscription)
