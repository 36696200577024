import React, { FC, FormEvent } from 'react'
import { Form, Icon } from 'antd'

import {
  FormTitle,
  FormDescription,
  PasswordDescription,
} from '../../styledComponents'

import { FormItem } from 'common/components/FormItem'
import { Button } from 'common/components/Button'
import { InputIcon } from 'common/components/InputIcon'
import { Input } from 'common/components/Input'
import { Link } from 'react-router-dom'
import isEmail from 'helpers/isEmail'

interface ResetPasswordFormProps {
  resetPassword: (email: string) => void
  setEmail: (email: string) => void
  error: string | null
  email: string
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  email,
  setEmail,
  error,
  resetPassword,
}) => {
  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!isEmail(email)) return

    if (email) {
      resetPassword(email)
    }
  }

  return (
    <div>
      <FormTitle>Reset password.</FormTitle>
      <FormDescription>
        Enter your email address and we'll send you an email with instructions
        to reset your password.
      </FormDescription>

      <Form onSubmit={submitForm}>
        <FormItem
          validateStatus={
            error || (!isEmail(email) && email) ? 'error' : undefined
          }
          help={
            error ? error : email && !isEmail(email) && 'Invalid email address'
          }
        >
          <Input
            placeholder='Your e-mail'
            prefix={<InputIcon type='mail' />}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormItem>
        <Button
          style={{ marginTop: 15 }}
          type='primary'
          shape='round'
          htmlType='submit'
          data-testid='test-password-reset'
          disabled={!isEmail(email)}
        >
          Reset password
          <Icon type='right' />
        </Button>
        <PasswordDescription>
          If you want to login, <Link to='/login'>click here</Link>.
        </PasswordDescription>
      </Form>
    </div>
  )
}

export default ResetPasswordForm
