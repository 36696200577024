import React, { useEffect, useState } from 'react'
import { Icon } from 'antd'
import ViewContainer from 'common/components/ViewContainer'

import { Button } from 'common/components/Button'
import { RoutePaths } from 'core/Router/routePaths'
import { Empty } from 'common/components/Empty'
import { ContentContainer } from 'common/components/ContentContainer'
import UsersTable from './UsersTable'
import { User } from 'common/store/schema/team'
import { Link, useHistory } from 'react-router-dom'
import { Modal } from 'antd'
import { Input } from 'common/components/Input'
import { InputIcon } from 'common/components/InputIcon'
import { SectionHeader, TodaySection } from 'core/Dashboard/styledComponents'
import { ActionsContainer } from 'core/Partners/styledComponents'

const { confirm } = Modal

interface TeamProps {
  fetchUsers: ( currentPage: number,
    perPage: number,
    sortBy: string,
    order: string,
    filterByPartner: string | null,
    filterByName: string | null, search : string | null) => void
    deleteUser: (userId: number,currentPage: number, perPage: number,sortBy: string, order: string,
            filterByPartner: string | null,filterByName: string | null) => void
    users: User[]
    activeUser: string
    totalTeamMember: number
}

const Team: React.FC<TeamProps> = ({
  fetchUsers,
  users,
  activeUser,
  deleteUser,
  totalTeamMember
}) => {

  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState("name")
  const [order, setOrder] = useState("ASC")
  const [currentPage, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [filterByPartner, setfilterByPartner] = useState("")
  const [filterByRole, setfilterByRole] = useState("")
  const [filterByName, setByName] = useState("")
  let history = useHistory()

  useEffect(() => {
    fetchUsers(currentPage,perPage,sortBy,order,filterByPartner,filterByName,search,filterByRole)
  }, [fetchUsers,currentPage,perPage,sortBy,order,search,filterByPartner,filterByName,filterByRole])

  //Sort ASC / DESC
  const onSortChange = (sortBy: string, order: string | null) => {
    if(sortBy)
      setSortBy(sortBy)    
    if(order)
      setOrder(order)
  }
  
  //When name/partner filter is selected
  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {
    if(filterField === "name")
      onSearched("name", filterValue)

    if(filterField === "partner")
      onSearched("partner", filterValue)

    if(filterField === "role")
      onSearched("role", filterValue)
  }
  
  //Set the state for the API
  const onSearched = (field:string, value: string | null) => {

    if(field === "name")
      setByName(value ? value : "");
    else if(field === "partner")
      setfilterByPartner(value ? value : "")
    else if(field === "role")
      setfilterByRole(value ? value : "")
    setPage(1)
  }

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim())
    setPage(1)
  }
  
  //Pagination
  const onPageChange = (currentPage: number) => {
    setPage(currentPage)
  }

  //Number of data per page change event
  const onPageSizeChange = (value: number) => {
    setPerPage(value)
    setPage(1)
  }

  //Delete the user and provide confirmation
  const showDeleteUserConfirmation = (username: string, email: string ,userId: number) => {
    confirm({
      title: 'Are you sure you want to delete this user?',
      content: `Username: ${email}`,
      okText: 'Yes',
      onOk() {
        deleteUser(userId,currentPage,perPage,sortBy,order,filterByPartner,filterByName)
      }
    })
  }

  //Edit the selected Team Member
  const editTeamMember = (userId: number) => {
    history.push({
      pathname: `${RoutePaths.EDIT_TEAM_MEMBER}/${userId}`,
      search: `?currentPage=${currentPage}&perPage=${perPage}&sortBy=${sortBy}&order=${order}&filterByPartner=${filterByPartner}&filterByName=${filterByName}`,
    })
  }

  return (
    <ViewContainer title='Team'>
      {(users.length === 0 && !search) && (
        <ContentContainer>
          <Empty description='You have no team members registered yet.'>
            <Button
              type='primary'
              shape='round'
              href={RoutePaths.ADD_NEW_TEAM_MEMBER}
            >
              <Icon type='plus-circle' />
              Add New Team Member
            </Button>
          </Empty>
        </ContentContainer>
      )}

      {(users.length > 0 || search) && (
        <>
          <TodaySection>
            <SectionHeader>
              <Button
                type='primary'
                shape='round'
                style={{ margin: '0 20px 0 0' }}
              >
                <Link to={RoutePaths.ADD_NEW_TEAM_MEMBER}>
                  <Icon type='plus-circle' style={{ marginRight: 10 }} />
                  Add New Team Member
                </Link>
              </Button>
              <ActionsContainer>
                <Input
                    placeholder='search by Team Name'
                    onChange={updateSearch}
                    style={{ width: 200 }}
                    prefix={<InputIcon type='search' />} />
              </ActionsContainer>
            </SectionHeader>
            <ContentContainer>
              <UsersTable
                users={users}
                activeUser={activeUser}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                onFilterApplied={onFilterApplied}
                onSortChange={onSortChange}
                currentPage={currentPage}
                perPage={perPage}
                totalTeamMember={totalTeamMember}
                showDeleteUserConfirmation={showDeleteUserConfirmation}
                editTeamMember={editTeamMember}
              />
            </ContentContainer>
          </TodaySection>
        </>
      )}
    </ViewContainer>
  )
}

export default Team
