import Aggregators from './Aggregators'

import { updateAggregator, fetchAggregators } from 'common/store/aggregators/actions'
import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'

const mapDispatchToProps = {
    fetchAggregators,
    updateAggregator: updateAggregator
}

const mapStateToProps = (state: State) => {
  return {
    aggregators: state.aggregator.aggregators,
    totalTeamMember: state.aggregator.totalAggregator,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aggregators)
