import React, { useState } from 'react'
import { Input } from 'common/components/Input'
import { Label } from './styledComponents'
import { Row, Switch, Modal } from 'antd'
import DataTable from 'common/components/DataTable/datatable'
import { SaaSSubscriptionKey, SaaSSubscriptionMode } from 'common/store/accounts/actions'
import { Button } from 'common/components/Button'
import Icon from 'antd/lib/icon'
import { SaaSSubscriptionModel } from 'common/store/schema/accounts'

const { confirm } = Modal

enum subScriptionGroup {
  voucher = "voucher",
  topup = "topup"
}

interface SaaSFormProps {
  topUpSaaSSubscriptionDisplay?: boolean,
  id: number | null,
  disabledTopUpSaaSSubscription: boolean,
  disabledBrandSaaSSubscription: boolean,
  organizationName: string,
  prefundCurrency: string,
  topUpsubscriptionData: Array<SaaSSubscriptionModel>,
  brandsubscriptionData: Array<SaaSSubscriptionModel>,
  setAccountSaaSDisable: (id: number,key : SaaSSubscriptionKey ,mode: SaaSSubscriptionMode) => void
  updateSaaSSubscriptionForAccount: (id: number,subscriptionGroup: string,SaasSSubscription : Array<SaaSSubscriptionModel>) => void
}
const SaaSSubscription: React.FC<SaaSFormProps> = ({
  topUpSaaSSubscriptionDisplay,
  id,
  disabledTopUpSaaSSubscription,
  disabledBrandSaaSSubscription,
  organizationName,
  topUpsubscriptionData,
  brandsubscriptionData,
  prefundCurrency,
  updateSaaSSubscriptionForAccount,
  setAccountSaaSDisable
}) => {

  const saasKey = topUpSaaSSubscriptionDisplay ? SaaSSubscriptionKey.TopUp : SaaSSubscriptionKey.Voucher
  const subscriptionGroup = topUpSaaSSubscriptionDisplay ? subScriptionGroup.topup : subScriptionGroup.voucher
  const disabled = topUpSaaSSubscriptionDisplay ? disabledTopUpSaaSSubscription : disabledBrandSaaSSubscription

  const headings = [
    'Pricing Tier',
    `Minimum (${prefundCurrency})`,
    `Maximum (${prefundCurrency})`,
    `Monthly Fee (${prefundCurrency})`,
  ];

  const subscriptionData = topUpSaaSSubscriptionDisplay ? topUpsubscriptionData : brandsubscriptionData
  
  let displaySubData = subscriptionData.length ? subscriptionData.map(data=>{
    return {
      pricingTier : data.pricingTier,
      minimumTxnAmount : data.minimumTxnAmount,
      maximumTxnAmount : data.maximumTxnAmount,
      monthlyFee : data.monthlyFee
    }
  }) : [{
    pricingTier: 1,
    minimumTxnAmount : 0,
    maximumTxnAmount : 0,
    monthlyFee : 0,
  }, { pricingTier: 2,minimumTxnAmount : 0,maximumTxnAmount : 0,monthlyFee : 0}]

  const[saasData, setSaasDataState] = useState(displaySubData);

  //Disaplay the confirmation
  const onChange = (checked : boolean, id: number | null) =>{
    if(id)
      showConfirmation(id,checked)
  }

  //Confirm before enabling / disabling the SaaS Subscription
  const showConfirmation = (id: number, disable: boolean) => {
    let content = `Are you sure you would like to disable the ${saasKey} SaaS Subscription for the ${organizationName}? This would mean all requests from the given partner will be denied?`
    if(!disable)
      content = `Are you sure you would like to enable the ${saasKey} SaaS Subscription for the ${organizationName}? This would mean all requests from the given partner will be granted?`

    confirm({
      title: '',
      content: content,
      okText: 'Yes',
      onOk() {
        if(id){
          const mode = disable ? SaaSSubscriptionMode.DisableSaaSSubscription : SaaSSubscriptionMode.EnableSaaSSubscription
          setAccountSaaSDisable(id,saasKey ,mode)
        }
      }
    })
  }

  //Add new empty row to the table
  const addNewRow = () =>{
    const newTier : Array<SaaSSubscriptionModel> = [{
      pricingTier: saasData.length+1,
      minimumTxnAmount : saasData[saasData.length-1].maximumTxnAmount + 1,
      maximumTxnAmount : 0,
      monthlyFee : 0,
    }]
    setSaasDataState([...saasData,...newTier])
  }

  const saveSaaSSubscription = (id: number | null) =>{
    console.log(saasData)
    if(id){
      let postSaasData: any = {}
      postSaasData["subscription"] = saasData
      updateSaaSSubscriptionForAccount(id,subscriptionGroup,postSaasData)
    }
  }

  //Remove the last tier from the table
  const deleteLastTier = () =>{
    let filteredArray = saasData.filter((item, index) => index !== (saasData.length - 1))
    filteredArray[filteredArray.length - 1].maximumTxnAmount = 0
    setSaasDataState(filteredArray);
  }

  const onChangeText = (columnIndex: number, rowIndex: number, value: string) =>{
    const newArray = saasData.map((data, index, oldArray)=>{
      if(rowIndex === (index+1)){
        switch (columnIndex) {
          case 1:
            data.minimumTxnAmount = +value
            break;
          case 2:
            data.maximumTxnAmount = +value
            if(oldArray[index+1]) oldArray[index+1].minimumTxnAmount = (+value) + 1
            break;
          case 3:
            data.monthlyFee = +value
            break;
          default:
            break
        }
      }
      return data
    })

    console.log(columnIndex)

    console.log(newArray)
    setSaasDataState(newArray);
  }

  return (
    <>
      <Row type='flex' align='middle' style={{ marginBottom : '20px' }}>
        <Input.Group style={{ width: '30%', height : '20px' }}>
          <Label>Disable the SaaS Subscription for {saasKey} </Label>
        </Input.Group>
        <Switch checked={disabled}  onChange={(checked) => onChange(checked, id)}/>
      </Row>

      <DataTable onChangeText={onChangeText} headings={headings} rows={saasData} deleteLastTier = {deleteLastTier}/>

      <div>
        <Button
          type='primary'
          shape='round'
          style={{ margin: '5px 0' }}
          onClick={() => addNewRow()}
        >
          <Icon type='plus' />
          Add New Tier
        </Button>
      </div>

      <Row type='flex' justify='end'>
            <Button
              htmlType='submit'
              form='setUser'
              type='primary'
              shape='round'
              onClick={() => saveSaaSSubscription(id)} >
              Save
              <Icon type='right' />
            </Button>
      </Row>

    </>
  )
}
export default SaaSSubscription
