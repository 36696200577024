import CatalogAudit from './CatalogAudit'
import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'
import { fetchBrands } from 'common/store/brandData/actions'

const mapDispatchToProps = {
  fetchBrands,
}

const mapStateToProps = (state: State) => {
  return {
    brands: state.brands.brands,
    totalCount: state.brands.totalCount
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogAudit)
