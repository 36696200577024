import { connect } from 'react-redux'

import { createTeamMember } from 'common/store/team/actions'
import AddTeamMember from './AddTeamMember'
import { History } from 'history'
import { Dispatch, AnyAction } from '@reduxjs/toolkit'

interface OwnProps {
  history: History
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  ownProps: OwnProps
) => {
  return {
    onSubmit: (values: object) => {
      createTeamMember(values, ownProps.history)(dispatch)
    }
  }
}

export default connect(null, mapDispatchToProps)(AddTeamMember)
