import { connect } from 'react-redux'
import BrandListBasedOnType from './BrandListBasedOnType'
import { fetchBrandForSelectedType , deleteProduct, addProduct } from 'common/store/brandListFromType/actions'
import { ProductListSelectors } from 'common/store/brandListFromType/slice'
import { State } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'

const mapStateToProps = (state: State) => {
  return {
    brandList: ProductListSelectors.getProductList(state),
    isLoading: ProductListSelectors.getIsLoading(state),
    totalList: ProductListSelectors.getTotalCount(state),
    brandCountryList: appSelectors.getBrandCountries(state),
  }
}

const mapDispatchToProps = {
  fetchBrandForSelectedType,
  deleteProduct,
  addProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandListBasedOnType)
