import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { RoutePaths } from 'core/Router/routePaths'
import LoginContainer from '../LoginContainer'
import PageContainer from 'common/components/PageContainer'
import PublicPageContainer from 'common/components/PublicPageContainer'
import PublicRoute from './PublicRoute'

export const Routes: React.FC = () => (
  <>
    <Route
      path={[
        RoutePaths.LOGIN,
        RoutePaths.RESET_PASSWORD,
        RoutePaths.CHANGE_TEMPORARY_PASSWORD,
      ]}
      component={LoginContainer}
    />
    <PrivateRoute
      path={[
        RoutePaths.DASHBOARD,
        RoutePaths.ADD_NEW_PARTNER,
        `${RoutePaths.EDIT_PARTNER}/:accountId`,
        RoutePaths.ADD_NEW_TEAM_MEMBER,
        `${RoutePaths.EDIT_TEAM_MEMBER}/:userId`,
        RoutePaths.PARTNERS,
        RoutePaths.TEAM,
        RoutePaths.TRANSACTIONS,
        RoutePaths.SETTINGS,
        RoutePaths.HISTORY,
        RoutePaths.EXCLUSION,
        RoutePaths.SUPERADMINTRANSACTION,
        RoutePaths.MAPPED_PRODUCTS,
        RoutePaths.ADD_PERSISTENT_LIST,
        RoutePaths.ADD_EXCLUSION_LIST,
        RoutePaths.AGGREGATORS,
        RoutePaths.CATALOG_AUDIT,
        RoutePaths.ERRORLOGS,
        RoutePaths.BROADCAST,
        RoutePaths.MY_ERROR_LOGS,
      ]}
      component={PageContainer}
    />

    <PublicRoute
      path={[
        RoutePaths.REDEEM,
        `${RoutePaths.REDEEM_BRAND_DETAILS}/:productId/:denomination`,
        `${RoutePaths.REDEEM_BRAND_DETAILS}/:productId`,
        `${RoutePaths.REDEEM_BRAND_PUCRAHSE_STATUS}/:orderId`,
        RoutePaths.ISSUES,
      ]}
      component={PublicPageContainer}
    />

    <Route
      path='/'
      exact
      render={() => <Redirect to={RoutePaths.DASHBOARD} />}
    />
  </>
)
