import { setApiKey, setSecret, setAccountDisable,Mode } from 'common/store/accounts/actions'
import { connect } from 'react-redux'

import AccessKeys from './AccessKeys'
import { State } from 'common/store/rootReducer'
import { accountSelectors } from 'common/store/accounts/slice'

const mapDispatchToProps = {
  setApiKey: (id: number, mode: Mode) => setApiKey(id, mode),
  setSecret: (id: number, mode: Mode) => setSecret(id, mode),
  setAccountDisable: (id: number, mode: Mode) => setAccountDisable(id, mode),
}

const mapStateToProps = (state: State) => {
  const { currentAccountId } = state.accounts
  if (!currentAccountId) {
    return {
      id: null,
      disabled: true,
      organizationName: '',
      apiKey: '',
      requestSecret: ''
    }
  }

  const currentAccount = accountSelectors.getCurrentAccount(state)

  return {
    id: currentAccount.id,
    disabled: currentAccount.disabled,
    organizationName: currentAccount.organizationName,
    apiKey: currentAccount.apiKey,
    requestSecret: currentAccount.requestSecret,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessKeys)
