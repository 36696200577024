import { createAccount } from 'common/store/accounts/actions'
import { resetActiveAccount } from 'common/store/accounts/slice'
import { fetchApplicationData } from 'common/store/app/actions'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import AddNewPartner from './AddNewPartner'
import { State } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'

const mapDispatchToProps = {
  createAccount,
  resetActiveAccount,
  fetchApplicationData
}

const mapStateToProps = (state: State) => {
  return {
    requestError: state.accounts.error,
    accountId: state.accounts.currentAccountId,
    currencyList: appSelectors.getCurrencies(state)
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewPartner)
)
