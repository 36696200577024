import React from 'react'
import { Form, Icon } from 'antd'

import { FormTitle, FormDescription } from '../../styledComponents'

import { Button } from 'common/components/Button'

import { InputIcon } from 'common/components/InputIcon'

import { History } from 'history'
import { Formik, Field } from 'formik'
import validationsSchema from './validationsSchema'
import FieldInput from 'common/components/FieldInput'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface ConfirmPasswordFormType{
  confirmPassword: (
    email: string,
    password: string,
    verificationCode: string,
    history: History
  ) => Promise<void>
  email: string
}

const ConfirmPasswordForm: React.FC<ConfirmPasswordFormType &
  RouteComponentProps> = ({ email, confirmPassword, history }) => {
  return (
    <div>
      <FormTitle>Choose password.</FormTitle>
      <FormDescription>
        Choose your combination of 8-30 characters, including at least one
        UPPERCASE, one lowecase, and one number.
      </FormDescription>
      <Formik
        initialValues={{
          verificationCode: '',
          newPassword: '',
          passwordConfirm: ''
        }}
        onSubmit={async values => {
          await confirmPassword(
            email,
            values.newPassword,
            values.verificationCode,
            history
          )
        }}
        validationSchema={validationsSchema}
      >
        {({ handleSubmit, errors, dirty, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name='verificationCode'
              placeholder='Enter your verification code'
              component={FieldInput}
            />
            <Field
              name='newPassword'
              placeholder='Type your new password'
              component={FieldInput}
              icon={<InputIcon type='safety' />}
              type='password'
            />
            <Field
              name='passwordConfirm'
              placeholder='Re-type your new password'
              component={FieldInput}
              icon={<InputIcon type='safety' />}
              type='password'
            />

            <Button
              style={{ marginTop: 15 }}
              type='primary'
              shape='round'
              htmlType='submit'
              data-testid='test-password-confirm'
              disabled={
                isSubmitting || !dirty || Object.keys(errors).length > 0
              }
            >
              Reset password
              <Icon type='right' />
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default withRouter(ConfirmPasswordForm)
