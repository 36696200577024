import React from 'react'
import { Form, Icon } from 'antd'
import { Formik } from 'formik'
import { Location, History } from 'history'
import qs from 'query-string'

import validationsSchema from './validationsSchema'
import { FormTitle, FormDescription } from '../styledComponents'

import { Button } from 'common/components/Button'
import { FormItem } from 'common/components/FormItem'
import { InputIcon } from 'common/components/InputIcon'
import { Input } from 'common/components/Input'

interface ChangeTemporaryPasswordType {
  changeTemporaryPassword: (
    email: string,
    oldPassword: string,
    newPassword: string,
    history: History
  ) => void

  location: Location
  history: History
}

const ChangeTemporaryPassword: React.FC<ChangeTemporaryPasswordType> = ({
  location,
  changeTemporaryPassword,
  history
}) => {
  const initialValues = {
    email: (qs.parse(location.search, { decode: false }).email as string) || '',
    oldPassword: '',
    newPassword: ''
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        changeTemporaryPassword(
          values.email,
          values.oldPassword,
          values.newPassword,
          history
        )
      }}
      validationSchema={validationsSchema}
    >
      {({
        errors,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        touched,
        values
      }) => {
        return (
          <div>
            <FormTitle>Change Temporary Password</FormTitle>
            <FormDescription>
              Choose your combination of 8-30 characters, including at least one
              UPPERCASE, one lowecase, and one number.
            </FormDescription>
            <Form onSubmit={handleSubmit}>
              <FormItem
                help={touched.email && errors.email ? errors.email : undefined}
                validateStatus={
                  touched.email && errors.email ? 'error' : undefined
                }
              >
                <Input
                  title='Your e-mail address'
                  placeholder='Your e-mail'
                  prefix={<InputIcon type='mail' />}
                  onChange={e => {
                    setFieldTouched('email')
                    setFieldValue('email', e.target.value)
                  }}
                  value={values.email}
                />
              </FormItem>
              <FormItem
                help={
                  touched.oldPassword && errors.oldPassword
                    ? errors.oldPassword
                    : undefined
                }
                validateStatus={
                  touched.oldPassword && errors.oldPassword
                    ? 'error'
                    : undefined
                }
              >
                <Input
                  title='Old password'
                  placeholder='Old Password'
                  prefix={<InputIcon type='safety' />}
                  type='password'
                  onChange={e => {
                    setFieldTouched('oldPassword')
                    setFieldValue('oldPassword', e.target.value)
                  }}
                  value={values.oldPassword}
                />
              </FormItem>
              <FormItem
                help={
                  touched.newPassword && errors.newPassword
                    ? errors.newPassword
                    : undefined
                }
                validateStatus={
                  touched.newPassword && errors.newPassword
                    ? 'error'
                    : undefined
                }
              >
                <Input
                  title='New password'
                  placeholder='New Password'
                  prefix={<InputIcon type='safety' />}
                  type='password'
                  onChange={e => {
                    setFieldTouched('newPassword')
                    setFieldValue('newPassword', e.target.value)
                  }}
                  value={values.newPassword}
                />
              </FormItem>
              <Button
                style={{ marginTop: 15 }}
                type='primary'
                shape='round'
                htmlType='submit'
                data-testid='test-password-confirm'
              >
                Change password
                <Icon type='right' />
              </Button>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default ChangeTemporaryPassword
