import React from 'react'
import { Form, Icon } from 'antd'
import { FormTitle, FormDescription,PasswordDescription } from '../styledComponents'
import { Button } from 'common/components/Button'
import { History } from 'history'
import { InputIcon } from 'common/components/InputIcon'
import { Formik, Field } from 'formik'
import validationsSchema from './validationsSchema'
import FieldInput from 'common/components/FieldInput'
import { Link } from 'react-router-dom'

interface SignInProps {
  login: (username: string, password: string, history: History) => void
  isSubmitting: boolean
  history: History
}

const SignIn: React.FC<SignInProps> = ({ login, isSubmitting, history }) => {
  return (
    <div>
      <FormTitle>Welcome to Phaze.</FormTitle>
      <FormDescription>
        Enter your email address and password to access your account.
      </FormDescription>
      <Formik
        initialValues={{ login: '', password: '' }}
        onSubmit={values => login(values.login, values.password, history)}
        validationSchema={validationsSchema}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name='login'
              placeholder='Login'
              component={FieldInput}
              icon={<InputIcon type='user' />}
            />
            <Field
              name='password'
              placeholder='Password'
              component={FieldInput}
              icon={<InputIcon type='safety' />}
              type='password'
            />
            <Button
              type='primary'
              shape='round'
              htmlType='submit'
              disabled={isSubmitting}
              data-testid='test-login'
            >
              Login
              <Icon type='right' />
            </Button>
            <PasswordDescription>
              If you want to reset your password,{' '}
              <Link to='/reset-password'>click here</Link>.
            </PasswordDescription>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SignIn
