import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'

import Transactions from './Transactions'

const mapStateToProps = (state: State) => {
  return {
  organizationName: state.auth.organizationName,
  role: state.auth.role
}}

export default connect(mapStateToProps, null)(Transactions)
