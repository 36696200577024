import * as yup from 'yup'
export default yup.object().shape({
  country : yup.string().required(),
  productId : yup.string().required(),
  mapped: yup.boolean(),
  clientFacingProductId : yup.string().nullable().when("mapped",{
    is : true,
    then: yup.string().required('Client Facing ProductId is required')
  })
})

export type FormValues = {
  country : number | string | undefined,
  productId: string
  clientFacingProductId: string | null
}
