
import { SHA256, enc} from 'crypto-js';
import axios from 'axios';
import { URL_BRAND_LIST, URL_BRAND_DETAILS, URL_BRAND_PURCHASE, URL_BRAND_PURCHASE_STATUS } from 'common/api/paths';
import { v4 as uuidv4 } from 'uuid';

//Brand Type
export type brandType = {
    brandName: string
    countryName: string
    currency: string
    foreignCurrency?: string
    denominations: number[]
    foreignDenominations?: number[]
    valueRestrictions: {}
    foreignValueRestrictions?: {}
    productId: number
    productImage: string
    productDescription: string
    termsAndConditions: string
    howToUse: string
    expiryAndValidity: string
    discount?: number
}

//Purchase Object response Type
export type purchaseResponseObject = {
    id: number,
    accountId : number,
    productId : string,
    productName : string,
    orderId: string,
    externalUserId: string,
    contentProviderCode ?: string,
    voucherDiscountPercent: number,
    baseCurrency : string,
    voucherCurrency: string,
    faceValue: number,
    priceInCAD?: number,
    faceValueInBaseCurrency?: number,
    currencyConversions? : any,
    cost: number,
    commission: number,
    commissionInCAD?: number,
    phazeCommission: number,
    phazeCommissionInCAD?: number,
    partnerRevenueSharePercent: number | string,
    deliveryFee: number,
    status: string,
    error?: string | undefined,
    vouchers : Array<any>,
    productDescription?: String,
    termsAndConditions?: String,
    howToUse?: String,
    expiryAndValidity?: String,
    created_at?: Date,
    updated_at: Date,
    deleted_at?: Date,
    transactionType?: number,
    account?: {
        id : number,
        organizationName: string,
        prefundCurrency: string,
        balance: number,
    }
}

//Generate SIgnature to make API call
export const generateSignature = (bodyData : any,method: string,path: string): string =>{
    let body = ''
    if (bodyData && Object.keys(bodyData).length > 0) {
        body = JSON.stringify(JSON.parse(bodyData))
    }
  
    let signature = SHA256(`${method}${path}${process.env.REACT_APP_REDEEM_SECRET_KEY}${body}`).toString(enc.Hex);
    return signature
}

//Fetch list of brands and filter the QC brands - currently available for India
export const fetchBrands = async () => {
    try {
     
      let currentPage= 1;
      let perPage = 50;
      let totalCount = 50;
      let brands: Array<any> = [] 
  
      do{
        const res = await axios.get(process.env.REACT_APP_REDEEM_API_BASE+ URL_BRAND_LIST('India',currentPage), { 
          headers:{
            'API-Key': process.env.REACT_APP_REDEEM_API_KEY,
            'signature':  generateSignature('','GET',URL_BRAND_LIST('India',currentPage)),        
          }
        })
  
        currentPage = res.data.currentPage+1
        totalCount = res.data.totalCount
        brands.push(...res.data.brands)
      }while(perPage*(currentPage-1) <= totalCount)
  
      //Filter the QC brands
      const qcBrands = brands.filter((brand: brandType) =>{
        return Number.parseInt(brand.productId.toString().substring(0,3)) === 104
      })
  
      return qcBrands;
    } catch (err) {
      return []
    }
}

//Fethc the brand details for the provided productId
export const fetchBrandDetails = async (productId: string) => {
    try {
     
        const res = await axios.get(process.env.REACT_APP_REDEEM_API_BASE+ URL_BRAND_DETAILS(productId,'INR'), { 
          headers:{
            'API-Key': process.env.REACT_APP_REDEEM_API_KEY,
            'signature':  generateSignature('','GET',URL_BRAND_DETAILS(productId,'INR')),        
          }
        })
  
      return res.data;
    } catch (err) {
      return []
    }
}

//Start the purchase brand
export const purchaseBrand = async(productId: number, denomination:number) =>{
  try {   
    //body
    const body = {
        "orderId": uuidv4(),
        "price": denomination,
        "productId": productId,
        "externalUserId": "QC_Phaze"
    }

    const res = await axios.post(process.env.REACT_APP_REDEEM_API_BASE+ URL_BRAND_PURCHASE,body, { 
        headers:{
            'API-Key': process.env.REACT_APP_REDEEM_API_KEY,
            'signature':  generateSignature(JSON.stringify(body),'POST',URL_BRAND_PURCHASE),        
        }
    })
    return res.data;
  } catch (err) {
    console.log(err)
    return err?.response
  }
}

//Get the status for the pending order. Retry until status is failed or pending
export const myRequest = (orderId: string, retries: number) : any => {  
    return axios.get(`${process.env.REACT_APP_REDEEM_API_BASE}${URL_BRAND_PURCHASE_STATUS(orderId)}`, { 
        headers:{
        'API-Key': process.env.REACT_APP_REDEEM_API_KEY,
        'signature':  generateSignature('','GET',URL_BRAND_PURCHASE_STATUS(orderId)),        
        }
    }).then ( async response => {
        console.log(response.data);
        if(response && response.data && ( response.data.status === 'processed')) {
        return  response.data;
        }else if(retries > 0){
        await wait(30000);
        return myRequest(orderId, retries -1 );
        }
        else {
        throw new Error('retries exceeded');
        }
    }).catch((err)=> {
        throw err
    })

    function wait(ms: number) {
        return new Promise( (resolve) => {setTimeout(resolve, ms)});
    }
}