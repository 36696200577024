import { deleteWebHook } from 'common/store/accounts/actions'
import { connect } from 'react-redux'

import WebHooks from './WebHooks'
import { State } from 'common/store/rootReducer'
import { accountSelectors } from 'common/store/accounts/slice'

const mapDispatchToProps = {
  deleteWebHook: deleteWebHook
}

const mapStateToProps = (state: State) => {
  const { currentAccountId } = state.accounts
  if (!currentAccountId) {
    return {
      id: null,
      webhook : [],
      organisationName:''
    }
  }

  const currentAccount = accountSelectors.getCurrentAccount(state)

  console.log(currentAccount)

  return {
    id: currentAccount.id,
    webhook : currentAccount.webhooks,
    organisationName: currentAccount.organizationName
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebHooks)
