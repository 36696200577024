import React, { FC, useEffect, useState } from 'react'

import ViewContainer from 'common/components/ViewContainer'
import { Button, Table } from 'antd'
import { client } from 'common/api/client'
import { TableContainer } from 'common/components/PaginatedTable/styledComponents'
import { ContentContainer } from 'common/components/ContentContainer'
import AddAdminModal from './AddAdminModal'
import { openNotification } from 'common/components/openNotification'

interface PartnerAdminsProps {}

type AdminUser = {
  id: string
  firstName: string
  lastName: string
  email: string
  role: string
}

const PartnerAdmins: FC<PartnerAdminsProps> = () => {
  const [admins, setAdmins] = useState<AdminUser[]>([])
  const [, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  // admin modal
  const [open, setOpen] = useState(false)

  const onOk = () => {
    setOpen(false)
    fetchAdmins()
  }

  const onCancel = () => {
    setOpen(false)
  }

  const fetchAdmins = () => {
    setLoading(true)
    client
      .get('/partner/admins')
      .then((r) => {
        console.log(r.data)
        setAdmins(r.data.admins)
        setCount(r.data.count)
      })
      .catch((e) => {
        openNotification(e.response?.data?.error || e.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchAdmins()
  }, [])

  return (
    <ViewContainer title='Admins'>
      <Button
        style={{ margin: '0px 0px 10px 2px' }}
        onClick={() => setOpen(true)}
      >
        Add User
      </Button>
      <AddAdminModal open={open} onOk={onOk} onCancel={onCancel} />
      <TableContainer>
        <ContentContainer>
          <Table loading={loading} pagination={false} dataSource={admins}>
            <Table.Column
              title='First Name'
              dataIndex='firstName'
              key='firstName'
            />
            <Table.Column
              title='Last Name'
              dataIndex='lastName'
              key='lastName'
            />
            <Table.Column title='Email' dataIndex='email' key='email' />
            <Table.Column
              title='Role'
              dataIndex='role'
              key='role'
              render={(text, record: AdminUser) => {
                return (
                  <div>
                    {record.role === 'partner_admin' ? 'Admin' : 'User'}
                  </div>
                )
              }}
            />
            <Table.Column
              title='Actions'
              dataIndex='actions'
              key='actions'
              render={(text, record: AdminUser) => {
                const deleteHandler = () => {
                  if (
                    !window.confirm(
                      'Are you sure you want to delete this admin?'
                    )
                  )
                    return

                  setLoading(true)
                  client
                    .delete(`/partner/admins/${record.id}`)
                    .then(() => {
                      openNotification('Admin deleted successfully', 'success')
                      fetchAdmins()
                    })
                    .catch((e) => {
                      openNotification(
                        e.response?.data?.error || e.message,
                        'error'
                      )
                    })
                }

                return record.role === 'partner_admin' ? null : (
                  <span>
                    <Button onClick={deleteHandler} type='danger'>
                      Delete
                    </Button>
                  </span>
                )
              }}
            />
          </Table>
        </ContentContainer>
      </TableContainer>
    </ViewContainer>
  )
}

export default PartnerAdmins
