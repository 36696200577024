import React, { useEffect, useState } from 'react'

import { RoutePaths } from 'core/Router/routePaths'
import { Link } from 'react-router-dom'

import Icon from 'antd/lib/icon'
import { Button } from 'common/components/Button'
import { Empty } from 'common/components/Empty'
import { Input } from 'common/components/Input'
import { InputIcon } from 'common/components/InputIcon'
import ViewContainer from 'common/components/ViewContainer'
import { ContentContainer } from 'common/components/ContentContainer'
import { ActionsContainer } from './styledComponents'

import useDownloadCSV from 'common/hooks/useDownloadCSV'
import { openNotification } from 'common/components/openNotification'

import PaginatedTable from 'common/components/PaginatedTable'
import columns from './columns'
import { Account } from 'common/store/schema/accounts'

import { Select } from 'common/components/Select'
import {
  TodaySection,
  SectionHeader,
} from '../Dashboard/styledComponents'
import { DateTime, Info } from 'luxon';
import { Role, RoleType } from 'common/store/auth/slice'

const { Option } = Select


type PartnersProps = {
  accounts: Account[]
  fetchAccounts: (
    currentPage?: number,
    perPage?: number,
    organizationName?: string
  ) => Promise<void>
  isLoading: boolean
  totalNumberOfAccounts: number
  countryList: Array<{name: string , code: string}>
  role: Role
}

const Partners: React.FC<PartnersProps> = ({
  accounts,
  fetchAccounts,
  isLoading,
  countryList,
  totalNumberOfAccounts,
  role
}) => {

 
  const fetchUrl = `/accounts/all/transactions`
  const dateTime = DateTime.now()
  const [search, setSearch] = useState('')
  const [currentPage, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [selectYear, setSelectYear] = useState(dateTime.toFormat('yyyy'))
  const [selectMonth, setSelectMonth] = useState(dateTime.toFormat('LLLL'))
  
  const defaultFromDate = DateTime.utc().startOf('month').toISO() //.format()
  const defaultToDate = DateTime.utc().endOf('month').toISO() //.format()

  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)
  const [canDoanload, setcanDoanload] = useState(true)

  const { downloadCSVcallback } = useDownloadCSV(`${fetchUrl}.csv`, "phaze")

  const currentMonth = dateTime.toFormat('LLLL')
  const currenctYear = dateTime.toFormat('yyyy')

  const listYear = [];
  for(let i = dateTime.year; i >= dateTime.year - 10; i--) {
    listYear.push(i)
  }

  //Map the countryList with countryCode to display the country name
  accounts = accounts.map(acc =>{
    const countryName = countryList.find(ctyList =>{
      return ctyList.code === acc.country
    })
    return { ...acc , "countryName" : countryName?.name || acc.country}
  })

  useEffect(() => {
    fetchAccounts(currentPage, perPage, search)
  }, [fetchAccounts, currentPage, perPage, search])

  const onPageChange = (currentPage: number) => {
    setPage(currentPage)
  }

  const onPageSizeChange = (value: number) => {
    setPerPage(value)
    setPage(1)
  }

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim())
    setPage(1)
  }

  const onSortChange = (sortBy: string, order: string | null) => {
  }

  const onFilterApplied = (actionName: string | null) => {
    console.log(actionName);
  }

  //Select the month 
  const handleYearSelection = (value: any) => {
    if(typeof value == 'number' || typeof value == 'string'){
      const getMonth = Info.months().findIndex(mth => mth === selectMonth)

      if((getMonth + 1) > +currentMonth && +currenctYear ===  value){
        const errorMessage = 'Please select a valid date range'
        openNotification(errorMessage, 'error')
        setcanDoanload(false)
      }else{
        setcanDoanload(true)
      }
        setSelectYear(value.toString())
        const startDate = DateTime.utc(+value,getMonth + 1).toISO() //.startOf('day') //.toFormat('yyyy-MM-dd')
        const endDate = DateTime.utc(+value,getMonth + 1).endOf('month').toISO() // .toFormat('yyyy-MM-dd') //.format();
        setFromDate(startDate)
        setToDate(endDate)
    }
  }

  //select the year
  const handleMonthSelection = (value: unknown) => {
    if(typeof value == 'number' || typeof value == 'string'){
      const getMonth = Info.months().findIndex(mth => mth === value)
      if((getMonth + 1) > +currentMonth && currenctYear ===  selectYear){
        const errorMessage = 'Please select a valid date range'
        openNotification(errorMessage, 'error')
        setcanDoanload(false)
      }else{
        setcanDoanload(true)
      }
      setSelectMonth(value.toString())
      const startDate = DateTime.utc(+selectYear,+getMonth + 1).toISO() // .toFormat('YYYY-MM-DD')
      const endDate = DateTime.utc(+selectYear,+getMonth + 1).endOf('month').toISO() // .toFormat('YYYY-MM-DD') //.format();
      setFromDate(startDate)
      setToDate(endDate)      
    }
  } 

  return (
    <ViewContainer title='Partners'>
      {accounts.length > 0 || search ? (
        <>
        <TodaySection>
          <SectionHeader>
            {role === RoleType.SuperAdmin ? (
              <Button
              type='primary'
              shape='round'
              style={{ margin: '0 20px 0 0' }}
            >
              <Link to={RoutePaths.ADD_NEW_PARTNER}>
                <Icon type='plus-circle' style={{ marginRight: 10 }} />
                Add New Partner
              </Link>
            </Button>
            ) : (<></>)}
              
            <ActionsContainer>
              <Select
                    onChange={handleMonthSelection}
                    defaultValue={selectMonth}
                    style={{ width: 140 }}
              >
                {Info.months().map((label, value) => (
                            <Option value={label} key={label} data-testid='test-custom--month-range-{{value}}'>
                            {label}
                            </Option>
                          ))}
              </Select>
              <Select
                onChange={handleYearSelection}
                defaultValue={selectYear}
                style={{ margin: '0 20px 0 0', width: 140 }}
              >
                {listYear.map(year => <Option value={year} key={year} data-testid='test-custom--month-range-{{year}}'>{year}</Option>)}
              </Select>
              <Button
                type='default'
                shape='round'
                disabled={!canDoanload}
                style={{ margin: '0 20px 0 0' }}
                onClick={() => downloadCSVcallback(fromDate, toDate)}
              >
                <Icon type='download' />
                Download Monthly Report
              </Button>
                <Input
                  placeholder='search by name'
                  onChange={updateSearch}
                  style={{ width: 200 }}
                  prefix={<InputIcon type='search' />}
                />
            </ActionsContainer>
            </SectionHeader>
              <ContentContainer>
                <PaginatedTable
                  total={totalNumberOfAccounts}
                  loading={isLoading}
                  data={accounts}
                  columns={columns}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  currentPage={currentPage}
                  pageSize={perPage}
                  onSortChange={onSortChange}
                  onFilterApplied={onFilterApplied}
                  rowKey= 'organizationName'
                />
              </ContentContainer>
          </TodaySection>
        </>
      ) : (
        <ContentContainer>
          <Empty description='You have no partners registered yet.'>
          {role === RoleType.SuperAdmin ? (
            <Button type='primary' shape='round'>
              <Link to={RoutePaths.ADD_NEW_PARTNER}>
                <Icon type='plus-circle' style={{ marginRight: 10 }} />
                Add New Partner
              </Link>
            </Button> ) : (<></>)}
          </Empty>
        </ContentContainer>
      )}
    </ViewContainer>
  )
}

export default Partners
