import React from 'react'
import { Container, MainSection } from './styledComponents'
import { Layout } from 'antd'

import { RoutePaths } from 'core/Router/routePaths'
import { Switch, Route } from 'react-router-dom'

import Redeem from 'core/Redeem'
import EditBrandDetails from 'core/Redeem/CardDetails'
import ConfirmBrandPurchase from 'core/Redeem/ConfirmBrandPurchase'
import PurchaseStatus from 'core/Redeem/ConfirmBrandPurchase/Purchase'
import Issues from 'core/Issues'
const { Content } = Layout

const PageContainer: React.FC = () => {
  return (
    <Container>
      <Layout>
        <MainSection>
          <Content>
            <Switch>
              <Route
                path={`${RoutePaths.REDEEM_BRAND_PUCRAHSE_STATUS}/:orderId`}
                component={PurchaseStatus}
              />
              <Route
                path={`${RoutePaths.REDEEM_BRAND_DETAILS}/:productId/:denomination`}
                component={ConfirmBrandPurchase}
              />
              <Route
                path={`${RoutePaths.REDEEM_BRAND_DETAILS}/:productId`}
                component={EditBrandDetails}
              />
              <Route path={RoutePaths.REDEEM} component={Redeem} />

              <Route path={RoutePaths.ISSUES} component={Issues} />
            </Switch>
          </Content>
        </MainSection>
      </Layout>
    </Container>
  )
}

export default PageContainer
