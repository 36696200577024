import React from 'react'
import {
  CardsContainer,
  Card,
  CardContent,
  CardTitle,
  CardAmount,
  CardSubContent
} from './styledComponents'
import Spin from 'antd/lib/spin'

interface CardsProps {
  data: any[]
  isCardGroup?: boolean
  isLoading?: boolean
}

const Cards: React.FC<CardsProps> = props => {
  return (
    <Spin spinning={props.isLoading} delay={300}>
      <CardsContainer isCardGroup={props.isCardGroup}>
        {props.data.map((card, key) => {
          return (
            <Card key={key} colour={card.bgColour}>
              {card.icon}
              <CardContent>
                <CardTitle>{card.title}</CardTitle>
                <CardAmount>{card.amount}</CardAmount>
                <CardSubContent>{card.subContent}</CardSubContent>
              </CardContent>
            </Card>
          )
        })}
      </CardsContainer>
    </Spin>
  )
}

export default Cards
