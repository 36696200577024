import React, { useEffect, useState } from 'react'
import {
  CardsContainer,
  CardTitle
} from './styledComponents'
import Spin from 'antd/lib/spin'
import { openNotification } from 'common/components/openNotification'
import { Button, Card } from 'antd'
import { useHistory } from 'react-router'
import { myRequest, purchaseResponseObject } from 'core/Redeem/fetchRedeem'

//After purchase load this page and ping the API every 30seconds to get the status
const Purchase = (props: any) => {

  const [isLoading,setIsLoading] = useState(true)
  const [purchaseData,setpurchaseData] = useState<Partial<purchaseResponseObject>>({
    status: 'pending',
    error: '',
    vouchers:[]
  })

  let history = useHistory();

  useEffect(()=> {
    const getData = async () =>{
      try{
        setIsLoading(true)
        const data = await myRequest(props.match.params.orderId, 5)
        if(data.status === 'processed'){
          setIsLoading(false)
          setpurchaseData(data)
        }
      }catch(err: any) {
        if(err?.response && err?.response.data && err.response.data.status === 'failed') {
          setIsLoading(false)
          setpurchaseData(err?.response.data)
        }else{
          setIsLoading(false)
          openNotification('Unable to process please try again','error')
        }
      }
    }
    getData()

  },[props.match.params.orderId])

  //Spread the voucher details for end user
  const voucherDetail = (voucher: any) =>{
    let data = []
    for(const property in voucher) {
      if(property === "misc"){
        // eslint-disable-next-line array-callback-return
        voucher[property].map((miscData : any) => {
          for(const prop in miscData){
            data.push(<div>{prop} : {miscData[prop]}</div>)     
          }
        })
      }else{
        data.push(<div>{property} : {voucher[property]}</div>)
      }
    }
    return data
  }

  const startOverHandler = () =>{
    history.push(`/redeem`)
  }

  const startOverButton = (
                        <Button onClick={() => startOverHandler()} size="large"
                            style={{ width: 170, marginLeft: '1%', marginTop: '10px' }} >
                          Start Over
                      </Button>)
  
  return (
    <Spin spinning={isLoading} delay={300}>
      {isLoading ? (
      <CardsContainer>
          <span>Your oder has been placed. Please be patient</span>
      </CardsContainer>) : 
      (<></>) }
      {purchaseData?.status === 'processed' ? (
      <>
        <CardsContainer>
          {purchaseData.vouchers?.map((voucher,key) => {
            return (
              <Card key={key}>
                <CardTitle >Your order has been processed</CardTitle>
                <div>Brand Name: {purchaseData.productName}</div>
                {voucherDetail(voucher)}
                <div>productDescription: {purchaseData.productDescription}</div>
                <div>Terms And Condition: {purchaseData.termsAndConditions}</div>
                {startOverButton}
              </Card>
            )
          })}
        </CardsContainer>
      </>) : (<></>)}
      {purchaseData?.status === 'failed' ? (<CardsContainer>
        <Card><span>{purchaseData.error}</span>{startOverButton}</Card></CardsContainer>) : (<></>)}
    </Spin>
  )
}

export default Purchase
