import React, { ReactType, FC } from 'react'
import {  Route } from 'react-router-dom'

interface PrivateRouteProps {
  component: ReactType
  path: string | string[]
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={props => {
        return <Component {...props} />
    }}
  />
)

export default PrivateRoute
