import React, { createContext } from 'react'

export enum PhazeEnv {
  Sandbox = 'sandbox',
  Production = 'production',
  Staging = 'staging',
  Integration = 'integration',
  Preprod = 'preprod'
}

type PhazeEnvContext = {
  phazeEnv: PhazeEnv
  phazeEnvToGoTo: PhazeEnv
  phazeEnvToGoToUrl: string
}

const envToGoToKey = 'REACT_APP_PHAZE_ENV_TO_GO_TO'
const phazeEnvToGoTo: PhazeEnv | undefined = process.env[
  envToGoToKey
] as PhazeEnv

if (!Object.values(PhazeEnv).includes(phazeEnvToGoTo)) {
  throw new Error(
    `Missing or invalid value for environment variable ${envToGoToKey}. Possible values are: ${Object.values(
      PhazeEnv
    ).join(', ')}.`
  )
}

const envToGoToUrlKey = 'REACT_APP_PHAZE_ENV_TO_GO_TO_URL'
const phazeEnvToGoToUrl: string | undefined = process.env[envToGoToUrlKey]

if (!phazeEnvToGoToUrl) {
  throw new Error(`Missing value for environment variable ${envToGoToUrlKey}.`)
}

const envAppBaseUrl = 'REACT_APP_API_BASE'
const phazeenvAppBaseUrl: string | undefined = process.env[envAppBaseUrl]

const isProd = phazeenvAppBaseUrl?.includes('https://api.phaze.io')

const envDetails = {
  phazeEnv:
    phazeEnvToGoTo === PhazeEnv.Production
      ? PhazeEnv.Preprod
        : phazeEnvToGoTo === PhazeEnv.Preprod
          ? PhazeEnv.Sandbox : (phazeEnvToGoTo === PhazeEnv.Sandbox && !isProd)
            ? PhazeEnv.Staging : PhazeEnv.Production,
  phazeEnvToGoTo,
  phazeEnvToGoToUrl
}

const phazeEnvContext = createContext<PhazeEnvContext>(envDetails)

const { Provider } = phazeEnvContext

export const PhazeEnvProvider: React.FC = ({ children }) => {
  return <Provider value={envDetails}>{children}</Provider>
}

export const { Consumer: PhazeEnvConsumer } = phazeEnvContext

export default phazeEnvContext
