import { connect } from 'react-redux'
import { fetchBrandForSelectedType , deleteProduct, addProduct } from 'common/store/brandListFromType/actions'
import { ProductListReducer, ProductListSelectors } from 'common/store/brandListFromType/slice'
import { State } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'
import AddPersistentList from './AddPersistentList'
import { authSelectors } from 'common/store/auth/slice'

const mapStateToProps = (state: State) => {
  return {
    brandCountryList: appSelectors.getBrandCountries(state),
  }
}

const mapDispatchToProps = {
  fetchBrandForSelectedType,
  deleteProduct,
  addProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPersistentList)
