import React, { FC } from 'react'
import { Menu, Icon, StyledLink } from './styledComponents'
import { RoutePaths } from 'core/Router/routePaths'

const PartnerUserNavigation: FC = () => {
  return (
    <Menu mode='inline'>
      <Menu.Item key='home'>
        <StyledLink to={RoutePaths.DASHBOARD}>
          <Icon type='home' /> <span>Dashboard</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Transactions'>
        <StyledLink to={RoutePaths.TRANSACTIONS}>
          <Icon type='swap' /> <span>Transactions</span>
        </StyledLink>
      </Menu.Item>
    </Menu>
  )
}

export default PartnerUserNavigation
