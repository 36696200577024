import { Dispatch } from '@reduxjs/toolkit'
import { openNotification } from 'common/components/openNotification'
import {  
  fetchAggregatorsRequest,
  fetchAggregatorsSuccess,
  fetchAggregatorsFailure,
  updateAggregatorRequest,
  updateAggregatorSuccess,
  updateAggregatorFailure
} from './slice'
import { client } from 'common/api/client'
import { URL_AGGREGATORS, URL_UPDATE_AGGREGATOR } from 'common/api/paths'
import { store } from '..'

export const fetchAggregators = (currentPage: number, perPage: number,
  sortBy: string, order: string) => async (dispatch: Dispatch) => {
  try {
  dispatch(fetchAggregatorsRequest())
  const res = await client.get(URL_AGGREGATORS, {
                                params: {
                                  currentPage, perPage,sortBy, order       
                                }
                      })
  dispatch(fetchAggregatorsSuccess(res.data))
  } catch (err) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchAggregatorsFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const updateAggregator = (aggregatorId: number, updatedAggregator: object) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(updateAggregatorRequest())
    await client.put(`${URL_UPDATE_AGGREGATOR(aggregatorId)}`, updatedAggregator)
    dispatch(updateAggregatorSuccess())
    store.dispatch(fetchAggregators(1,10,'aggregatorName', 'ASC'))
    openNotification('Aggregator has been updated', 'success')
  } catch (err) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(updateAggregatorFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}
