import { connect } from 'react-redux'

import { State } from 'common/store/rootReducer'
import { authSelectors } from 'common/store/auth/slice'
import PrivateRoute from './PrivateRoute'

const mapStateToProps = (state: State) => ({
  isAuthenticated: authSelectors.isAuthenticated(state)
})

export default connect(mapStateToProps, null)(PrivateRoute)
