import React from 'react'

import { FieldProps } from 'formik'

import { FormItem } from 'common/components/FormItem'
import { Select } from 'common/components/Select'
const { Option } = Select

interface OptionObject {
  key: string
  value: string | number
}

interface FieldSelectProps extends FieldProps {
  label?: string
  placeholder?: string
  options: OptionObject[]
  required?: boolean
  onChange?: () => void
  getValue?: (val : any) => void
}

const FieldSelect: React.FC<FieldSelectProps> = ({
  form: { touched, errors, setFieldTouched, setFieldValue, values },
  field: { name },
  label,
  placeholder,
  options,
  required,
  onChange,
  getValue
}) => {
  const errorMsg = touched[name] && errors[name]

  return (
    <>
      <FormItem
        help={errorMsg}
        validateStatus={errorMsg ? 'error' : undefined}
        label={label}
        htmlFor={name}
        required={required}
      >
        <Select
          filterOption={(input, option) => {
            return (
              String(option.props.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            )
          }}
          fullWidth
          showSearch
          placeholder={placeholder}
          onChange={async value => {
            await setFieldValue(name, value)
            if (onChange) {
              onChange()
            }
            if(getValue)
              getValue(value)
          }}
          value={values[name] || undefined}
          onBlur={() => setFieldTouched(name)}
          id={name}
        >
          {options.map((option, i) => (
            <Option value={option.value} key={`${option.key.trim()}_${i}`}>
              {option.key}
            </Option>
          ))}
        </Select>
      </FormItem>
    </>
  )
}
export default FieldSelect
