import { Form, Input, Modal } from 'antd'
import { client } from 'common/api/client'
import { openNotification } from 'common/components/openNotification'
import React, { FC, useState } from 'react'

type IAdminModalProps = {
  open: boolean
  onOk: () => void
  onCancel: () => void
}

const AddAdminModal: FC<IAdminModalProps> = ({ onCancel, onOk, open }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const submitHandler = (e: any) => {
    e.preventDefault()
    if (loading) return

    setLoading(true)

    client
      .post('/partner/admins', {
        firstName,
        lastName,
        email,
      })
      .then((r) => {
        openNotification('Admin added successfully', 'success')
        onOk()
      })
      .catch((e) => {
        openNotification(e.response?.data?.error || e.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title='Add User'
      visible={open}
      onOk={submitHandler}
      onCancel={onCancel}
    >
      <Form style={{ display: 'grid', gap: 5 }} onSubmit={submitHandler}>
        <Input
          disabled={loading}
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          placeholder='First Name'
        />

        <Input
          disabled={loading}
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          placeholder='Last Name'
        />

        <Input
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder='Email'
          type='email'
        />
      </Form>
    </Modal>
  )
}

export default AddAdminModal
