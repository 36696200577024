import React, { ReactType, SFC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { RoutePaths } from 'core/Router/routePaths'

interface PrivateRouteProps {
  component: ReactType
  isAuthenticated?: boolean
  path: string | string[]
}

const PrivateRoute: SFC<PrivateRouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={props => {
      if (isAuthenticated) {
        return <Component {...props} />
      }

      return <Redirect to={RoutePaths.LOGIN} />
    }}
  />
)

export default PrivateRoute
