import { Dispatch } from '@reduxjs/toolkit'
import {
  fetchApplicationDataRequest,
  fetchApplicationDataSuccess,
  fetchApplicationDataFailure
} from './slice'
import { client } from 'common/api/client'
import { URL_APPLICATION_DATA } from 'common/api/paths'

export const fetchApplicationData = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchApplicationDataRequest)
    const res = await client.get(URL_APPLICATION_DATA)
    dispatch(fetchApplicationDataSuccess(res.data))
  } catch (err) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchApplicationDataFailure(errorMessage))
  }
}
