import { connect } from 'react-redux'
import { State } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'
import CompanyInformationForm from './CompanyInformationForm'

const mapStateToProps = (state: State) => {
  return {
    countryList: appSelectors.getCountries(state)
  }
}

export default connect(mapStateToProps)(CompanyInformationForm)
