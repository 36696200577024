import React, { useState } from 'react'
import { Layout } from 'antd'
import { SiderContainer, ToggleIcon } from './styledComponents'
import PartnerNavigation from './PartnerNavigation'
import SuperNavigation from './SuperNavigation'
import PartnerUserNavigation from './PartnerUserNavigation'
import PhazeAdminNavigation from './PhazeUserNavigation'
import { RoleType } from 'common/store/auth/slice'

const { Sider } = Layout

interface NavigationProps {
  role?: string
}
const Navigation: React.FC<NavigationProps> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <SiderContainer>
      <Sider
        theme='light'
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        collapsedWidth={63}
      >
        <ToggleIcon
          type={isCollapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />

        {(props.role === 'super_admin') && <SuperNavigation />}
        {(props.role === RoleType.PhazeAdmin ) && <PhazeAdminNavigation />}
        {props.role === 'partner_admin' && <PartnerNavigation />}
        {props.role === 'partner_user' && <PartnerUserNavigation />}
      </Sider>
    </SiderContainer>
  )
}

export default Navigation
