import { connect } from 'react-redux'

import { withRouter, RouteComponentProps } from 'react-router-dom'

import { editTeamMember, fetchUsers } from 'common/store/team/actions'
import { Dispatch, AnyAction } from '@reduxjs/toolkit'
import { State } from 'common/store/rootReducer'
import { teamSelectors } from 'common/store/team/slice'

import EditTeamMember from './EditTeamMember'

interface RouteParams {
  userId: string
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  ownProps: RouteComponentProps
) => {
  return {
    fetchUsers: (currentPage: number, perPage: number,sortBy: string,order: string,
      filterByPartner: string | null,filterByName: string | null) => 
        fetchUsers(currentPage,perPage,sortBy,order,filterByPartner,filterByName)(dispatch),
    onSubmit: (userId: number, values: object) => {
      editTeamMember(userId, values, ownProps.history)(dispatch)
    }
  }
}

const mapStateToProps = (
  state: State,
  ownProps: RouteComponentProps<RouteParams>
) => {
  const { userId } = ownProps.match.params
  return {
    user: teamSelectors.getUserById(state, JSON.parse(userId))
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditTeamMember)
)
