import { connect } from 'react-redux'

import EditPartner from './EditPartner'
import {
  fetchAccountById,
  updateAccountById,
  archivePartner
} from 'common/store/accounts/actions'
import { resetActiveAccount } from 'common/store/accounts/slice'
import { State } from 'common/store/rootReducer'
import { accountSelectors } from 'common/store/accounts/slice'
import roundToDecimalPlaces from 'common/utils/roundToDecimalPlaces'

const mapStateToProps = (state: State) => {
  const account = accountSelectors.getCurrentAccount(state)
  const formattedAccount = account
    ? {
        ...account,
        partnersCommission: roundToDecimalPlaces(account.partnersCommission * 100, 2),
        maxDiscount: roundToDecimalPlaces(account.maxDiscount, 2),
        fxSpreadPercent: roundToDecimalPlaces(account.fxSpreadPercent * 100, 2),
        topUpFxSpreadPercent: roundToDecimalPlaces(account.topUpFxSpreadPercent * 100, 2),
        marginOffset: roundToDecimalPlaces(account.marginOffset, 2),
        transactionFee: roundToDecimalPlaces(account.transactionFee,2),
        breakageSplitPercent: roundToDecimalPlaces(account.breakageSplitPercent * 100, 2),
      }
    : null

  return {
    role: state.auth.role,
    account: formattedAccount
  }
}

const mapDispatchToProps = {
  fetchAccountById,
  updateAccountById,
  resetActiveAccount,
  archivePartner
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPartner)
