import React from 'react'

import { Menu, Icon, StyledLink } from './styledComponents'

import { RoutePaths } from 'core/Router/routePaths'

const PhazeAdminNavigation: React.FC = () => {
  return (
    <Menu mode='inline'>
      <Menu.Item key='home'>
        <StyledLink to={RoutePaths.DASHBOARD}>
          <Icon type='home' /> <span>Dashboard</span>
        </StyledLink>
      </Menu.Item>

      <Menu.Item key='partners'>
        <StyledLink to={RoutePaths.PARTNERS}>
          <Icon type='deployment-unit' /> <span>Partners</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Aggregators'>
        <StyledLink to={RoutePaths.AGGREGATORS}>
          <Icon type='apartment' /> <span>Aggregators</span>
        </StyledLink>
      </Menu.Item>
    </Menu>
  )
}

export default PhazeAdminNavigation
