import { logout, getUserInfo } from 'common/store/auth/actions'
import { connect } from 'react-redux'

import Header from './Header'
import { withRouter } from 'react-router-dom'
import { State } from 'common/store/rootReducer'

const mapDispatchToProps = {
  logout,
  getUserInfo
}

const mapStateToProps = (state: State) => ({
  email: state.auth.email
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
