import styled from 'styled-components'
import { Menu as MenuBase, Icon as IconBase } from 'antd'
import { NavLink } from 'react-router-dom'

export const SiderContainer = styled.div`
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
  background: ${({ theme }) => theme.colours.bgLight};
`

export const Menu = styled(MenuBase)`
  && {
    border: none;
    margin-top: 65px;
  }
  &&.ant-menu-inline-collapsed {
    width: 63px;
  }
  &&.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 23px !important;
  }

  && .ant-menu-item a:hover {
    color: ${({ theme }) => theme.colours.link};
  }

  && > .ant-menu-item-selected {
    background-color: transparent !important;
    ::after {
      border-color: ${({ theme }) => theme.colours.link};
    }
  }
`

export const Icon = styled(IconBase)`
  && {
    color: ${({ theme }) => theme.colours.icon};
  }
`
export const ToggleIcon = styled(Icon)`
  padding: 30px 23px;
`

export const StyledLink = styled(NavLink)`
  &.active,
  &:hover {
    i,
    span {
      color: ${({ theme }) => theme.colours.link};
    }
  }
`
