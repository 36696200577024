import React, { useState } from 'react'

import ResetPasswordForm from './ResetPasswordForm'
import ConfirmPasswordForm from './ConfirmPasswordForm'
import { History } from 'history'

interface ForgotPasswordProps {
  resetPassword: (email: string) => void
  confirmPassword: (
    email: string,
    password: string,
    verification: string,
    history: History
  ) => Promise<void>
  needsPasswordConfirm: boolean
  error: string | null
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  needsPasswordConfirm,
  confirmPassword,
  resetPassword,
  error
}) => {
  const [email, setEmail] = useState('')

  return (
    <div>
      {needsPasswordConfirm ? (
        <ConfirmPasswordForm email={email} confirmPassword={confirmPassword} />
      ) : (
        <ResetPasswordForm
          email={email}
          setEmail={setEmail}
          error={error}
          resetPassword={resetPassword}
        />
      )}
    </div>
  )
}

export default ForgotPassword
