import React from 'react'
import { Menu, Icon, StyledLink } from './styledComponents'
import { RoutePaths } from 'core/Router/routePaths'

const PartnerNavigation: React.FC = () => {
  return (
    <Menu mode='inline'>
      <Menu.Item key='home'>
        <StyledLink to={RoutePaths.DASHBOARD}>
          <Icon type='home' /> <span>Dashboard</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Transactions'>
        <StyledLink to={RoutePaths.TRANSACTIONS}>
          <Icon type='swap' /> <span>Transactions</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Settings'>
        <StyledLink to={RoutePaths.SETTINGS}>
          <Icon type='audit' /> <span>Settings</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Error Logs'>
        <StyledLink to={RoutePaths.MY_ERROR_LOGS}>
          <Icon type='file' /> <span>Error Logs</span>
        </StyledLink>
      </Menu.Item>
    </Menu>
  )
}

export default PartnerNavigation
