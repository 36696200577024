import React from 'react'

import { FieldProps } from 'formik'

import { Checkbox } from 'common/components/Checkbox'
import { FormItem } from 'common/components/FormItem'

interface FieldDateProps extends FieldProps {
  label?: string
  text?: string
  required?: boolean
}
const FieldDate: React.FC<FieldDateProps> = ({
  form: { errors, setFieldValue, values },
  field: { name },
  text,

  required
}) => {
  return (
    <>
      <FormItem
        help={errors[name]}
        validateStatus={errors[name] ? 'error' : undefined}
        htmlFor={name}
        required={required}
      >
        <Checkbox
          name={name}
          onChange={event => setFieldValue(name, event.target.checked)}
          checked={values[name]}
          id={name}
        >
          {text}
        </Checkbox>
      </FormItem>
    </>
  )
}
export default FieldDate
