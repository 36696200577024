import { Dispatch } from '@reduxjs/toolkit'
import { openNotification } from 'common/components/openNotification'

import {
    fetchConfigurationRequest,
    fetchConfigurationFailure,
    fetchConfigurationSuccess
} from './slice'

import { client } from 'common/api/client'
import { URL_CONFIGURATION_API } from 'common/api/paths'

export const fetchConfigurations = () => async (dispatch: Dispatch) => {
    try {
      dispatch(fetchConfigurationRequest())
      const res = await client.get(`${ URL_CONFIGURATION_API}`)
      dispatch(fetchConfigurationSuccess(res.data))
    } catch (err:any) {
      const errorMessage = err.response?.data.error || err.toString()
      dispatch( fetchConfigurationFailure(errorMessage))
      openNotification(errorMessage, 'error')
    }
  }


